<section class="kb-wrapper">
  <!-- Knowledge base search start -->
  <div class="kb-search">
    <div class="row">
      <div class="col-12">
        <div class="card bg-transparent shadow-none kb-header py-3">
          <div class="card-content">
            <div class="card-body text-center p-md-5">
              <h1 class="mb-2 kb-title">How can we help you today?</h1>
              <p class="mb-4">Algolia helps businesses across industries quickly create relevant, scalable, and
                lightning fast search and discovery experiences.</p>
              <form>
                <div class="form-group position-relative w-50 mx-auto kb-search-input">
                  <input type="text" class="form-control form-control-lg shadow round p-3 font-medium-1" id="searchbar"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="searchQuery" placeholder="Find from talk..">
                  <button class="btn btn-primary round position-absolute" type="button">
                    <span class="d-none d-sm-block">Search</span>
                    <i class="ft-search d-block d-sm-none"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Knowledge base search ends -->
  <!-- Knowledge base content start -->
  <div class="kb-content">
    <div class="row kb-content-info match-height mx-1 mx-md-2 mx-lg-5">
      <div class="col-md-4 col-sm-6 kb-content-card" *ngFor="let kb of knowledgeBase | search:'name': searchQuery">
        <div class="card">
          <div class="card-content">
            <div class="card-body text-center p-4">
              <a (click)="goToURL('')">
                <i [class]="kb.icon"></i>
                <h5 class="mt-2">{{kb.name}}</h5>
                <p class="m-0 text-muted">{{kb.content}}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 kb-no-content" *ngIf="(knowledgeBase | search:'name':searchQuery).length === 0">
        <div class="card bg-transparent shadow-none">
          <div class="card-content">
            <div class="card-body text-center">
              <p class="m-0 d-flex justify-content-center align-items-center">
                <i class="ft-alert-circle font-medium-2 mr-1"></i>
                <span>No result found</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Knowledge base content ends -->
</section>
