import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {HttpErrorResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {CustomizeToastrService} from '../services/costomize-toastr.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastrService: CustomizeToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (err: any) => {
          if (err.status === 404 || err.status === 401) {
            this.toastrService.PositionTopCenterError({
              title: 'Connexion Failed',
              message: 'Error Caught By Interceptor'
            })
          }
          if (err instanceof HttpErrorResponse) { // here you can even check for err.status == 404 | 401 etc
            this.toastrService.PositionTopCenterError({
              title: 'Connexion Failed',
              message: 'Error Caught By Interceptor'
            })
          }

        }
      ))
  }
}
