import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Formulaire} from '../../models/v1/formulaire';

@Component({
  selector: 'app-dynamic-form-assurance',
  templateUrl: './dynamic-form-assurance.component.html',
  styleUrls: ['./dynamic-form-assurance.component.scss']
})
export class DynamicFormAssuranceComponent implements OnInit {

  @Input() formulaire: Formulaire<string>;
  @Input() form: FormGroup;

  ngOnInit(): void {
  }

  get isValid() {
    return this.form.controls[this.formulaire.key] && this.form.controls[this.formulaire.key].valid;
  }

}
