import { CustomizeSwalService } from 'app/shared/services/costomize-swal.service';
import { SERVICES } from './../../models/helpers/utils.interface';
import { IUser } from './../../models/v1/user';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Formulaire } from 'app/shared/models/v1/formulaire';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'app/shared/data/data.service';
import { CustomizeToastrService } from 'app/shared/services/costomize-toastr.service';

export enum TypeModalContent {
  USER = 'USER',
  BAREME_AUTO = 'BAREME_AUTO',
  BAREME_SANTE = 'BAREME_SANTE',
  BAREME_VOYAGE = 'BAREME_VOYAGE',
  BAREME_ASSURANCE_INDIVIDUELLE = 'BAREME_ASSURANCE_INDIVIDUELLE',
  BAREME_CREDIT = 'BAREME_CREDIT',
  BAREME_HABITATION = 'BAREME_HABITATION',
  BAREME= 'BAREME'
}

@Component({
  selector: 'app-ngb-modal-content',
  templateUrl: './ngb-modal-content.component.html',
  styleUrls: ['./ngb-modal-content.component.scss']
})
export class NgbModalContentComponent implements OnInit {

  @ViewChild('myselectchamps') myselectchamps;

  @Input() Type: TypeModalContent;
  @Input() user: IUser;
  @Input() champs: Formulaire<any>[];
  @Input() inputsBaremes : string[] = [];
  @Input() outputsBaremes : string[] = [];
  @Input() titleBareme: string = '';
  @Input() intputsMatcheElementForms:any;


  
  baremeForm=  new FormGroup({
      item: new FormControl('')
  });

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    public toast: CustomizeToastrService,
    private swalService: CustomizeSwalService
  ) {

  }

  ngOnInit(): void {
    this.baremeForm.removeControl('item');
    this.inputsBaremes.forEach((champ) => {
      this.baremeForm.addControl(champ, new FormControl('', [Validators.required]),
      )
    });
    if(this.intputsMatcheElementForms){
      this.baremeForm.patchValue(this.intputsMatcheElementForms)
    }
  }

  valider() {
    switch (this.Type) {
      case TypeModalContent.USER:
        this.swalService
          .ConfirmText('question', 'Validation Compte', 'Êtes vous sûre de vouloir activer ce compte.')
          .then(
            (result) => {
              const { value } = result
              if (value) {
                this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Accounts/verify', this.user?.account?._id, {})
                  .then(
                    (data) => {
                      this.toast.PositionTopFullWidthSuccess({
                        title: 'Validation compte',
                        message: `Compte ${this.user?.firstname} ${this.user?.lastname} a été activé avec succès. `
                      })
                      this.activeModal.close()
                      window.location.reload();
                    },
                    (error) => {
                      console.log(error)
                    }
                  )
              }
            }
          )
        break;
      case TypeModalContent.BAREME:
        const baremeValue = this.baremeForm.value;
        this.dataService.updatedBaremeAutomobileSelection(baremeValue)
        this.activeModal.close()
        break;
      default:
        break;
    }
  }
  desactiver() {
    switch (this.Type) {
      case TypeModalContent.USER:
        this.swalService
          .ConfirmText('question', 'Desactivation Compte', 'Êtes vous sûre de vouloir desactiver ce compte.')
          .then(
            (result) => {
              const { value } = result
              if (value) {
                this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Accounts/deactivate', this.user?.account?._id, {})
                  .then(
                    (data) => {
                      this.toast.PositionTopFullWidthSuccess({
                        title: 'Désactivation compte',
                        message: `Compte ${this.user?.firstname} ${this.user?.lastname} a été desactivé avec succès. `
                      })
                      this.activeModal.close()
                      window.location.reload();

                    },
                    (error) => {
                      console.log(error)
                    }
                  )
              }
            }
          )
        break;
      case TypeModalContent.BAREME:
        const baremeValue = this.baremeForm.value
        this.dataService.updatedBaremeAutomobileSelection(baremeValue)
        this.activeModal.close()
        break;
      default:
        break;
    }
  }
}
