import { CreateRoleDTO, UpdateRoleDTO } from './../models/helpers/role';
import { Injectable } from '@angular/core';
import { IUser, UserDTO } from '../models/v1/user';
import { DataService } from '../data/data.service';
import { SERVICES } from '../models/helpers/utils.interface';
import { AccountDTO, IAccount } from '../models/v1/account';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  constructor(private dataService: DataService) {
  }

  getAllRolesProassur(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Roles/proassur')
        .then(
          (roles: any) => {
            resolve(roles);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllPermissionsProassur(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.dataService.get(SERVICES.USER_MANAGEMENT, 'Permissions/proassur')
          .then(
            (permissions: any) => {
              resolve(permissions);
            }
          )
          .catch((err: any) => {
            reject(err)
          })
      })
  }

  createPermissionProassur(payload): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.dataService.post(SERVICES.USER_MANAGEMENT, 'Permissions/proassur', payload)
          .then(
            (permissions: any) => {
              resolve(permissions);
            }
          )
          .catch((err: any) => {
            reject(err)
          })
      })
  }

  createRole(payload: CreateRoleDTO): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'Roles/proassur', payload )
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  updateRole(payload: UpdateRoleDTO): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `Roles/proassur/${payload._id}`, payload )
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  getRoleById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Roles/proassur/${id}`)
        .then(
          (roles: any) => {
            resolve(roles);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getPermissionsByRole(id: string) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Roles/proassur/${id}`)
        .then(
          (roles: any) => {
            resolve(roles);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  assignPermissionsTo(account: string, permissions: string[]) {
    const payload = {
      account,
      permissions
    }
    console.log('assignPermissionsTo() payload = ', payload)
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `Permissions/proassur/assign/${account}`, payload )
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

}
