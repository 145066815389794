<div class="modal-header">
  <h4 class="modal-title">{{ Type  ? titleBareme : 'Activation Compte Prestataire' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <!-- USER UPDATE -->
  <ng-container *ngIf="Type === 'USER'">
    <form>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-1">Prénom </label>
            <input type="text" [ngModel]="user.firstname" disabled id="basic-form-1" class="form-control"
              name="firstname">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-2">Nom</label>
            <input type="text" [ngModel]="user.lastname" disabled id="basic-form-2" class="form-control"
              name="lastname">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-3">E-mail</label>
            <input type="text" [ngModel]="user.account.email" id="basic-form-3" class="form-control" disabled
              name="email">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-4">Numéro Tel</label>
            <input type="string" [ngModel]="user.account.phoneNumber" id="basic-form-4" disabled class="form-control"
              name="phoneNumber">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-5">Société</label>
            <input type="text" [ngModel]="user.prestataire.label" id="basic-form-5" disabled class="form-control"
              name="label">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-5">Raison Social</label>
            <input type="text" [ngModel]="user.prestataire.socialReason" id="basic-form-5" disabled class="form-control"
              name="raisonSocial">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-5">Forme Juridique</label>
            <input type="text" [ngModel]="user.prestataire.legalForm" id="basic-form-5" disabled class="form-control"
              name="legalForm">
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="basic-form-5">Numéro D'incription</label>
            <input type="text" [ngModel]="user.prestataire.medicalRegistrationNumber" id="basic-form-5" disabled
              class="form-control" name="medicalRegistrationNumber">
          </div>
        </div>
      </div>

      <div class="form-group mb-2">
        <label for="basic-form-5">Spécialités</label>
        <ul class="list-group" style="height:150px;overflow-y:scroll">
          <li class="list-group-item" *ngFor="let spec of user.prestataire.specialities, let i=index">
            <span class="badge badge-warning mr-2">{{ i + 1 }}</span> {{ spec }}
          </li>
        </ul>
      </div>
    </form>
  </ng-container>
  
   <!-- Baremes inputs -->
  <ng-container *ngIf="Type !== 'USER' && baremeForm.controls && inputsBaremes.length > 0">
    <form [formGroup]="baremeForm" class="editForm" novalidate>
      <div class="row" *ngFor="let input of inputsBaremes">
        <div class="col-md-12">
          <div class="form-group">
            <div class="controls">
              <label class="text-secondary">{{input}}</label>
              <ng-select #myselectchamps [items]="champs" bindLabel="label" bindValue="key" [multiple]="false"
                 placeholder="{{input}}" [closeOnSelect]="true" formControlName="{{input}}" >
              </ng-select>
            </div>
          </div>
          
        </div>
      </div>
    </form>
  </ng-container>

</div>
<div class="modal-footer" *ngIf="Type !== 'USER'" style="text-align: center !important;" >
  <button type="button" (click)="valider()" class="btn btn-success mr-2"
  [disabled]="!baremeForm.valid"
  >
    <i class="ft-check-square mr-1" ></i>Valider</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Annuler</button>
</div>


<div class="modal-footer" *ngIf="Type === 'USER' && !user?.account?.isVerified" >
  <button type="button" (click)="valider()" class="btn btn-success mr-2">
    <i class="ft-check-square mr-1" ></i>Valider Compte</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')"
  [disabled]=""
  >Close</button>
</div>
<div class="modal-footer" *ngIf="Type === 'USER' && user?.account?.isVerified" >
  <button type="button" (click)="desactiver()" class="btn btn-danger mr-2">
    <i class="ft-check-square mr-1" ></i>Desactiver Compte</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
