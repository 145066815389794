import {Component, Input, OnInit} from '@angular/core';
import {ButtonInterface} from '../../models/helpers/utils.interface';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss']
})
export class HeaderContentComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() Buttons: ButtonInterface[];

  constructor(private _location: Location, private router: Router) {
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this._location.back();
  }

  goToUrl(url: string): void {
    this.router.navigate([url])
  }
}
