import { BaremeAutomobile } from './../models/v1/baremeAutomobile';
import { Formulaire } from './../models/v1/formulaire';
import { Medicament } from './../models/v1/medicament';
import { ActMedical } from './../models/v1/actMedical';
import { Groupe } from './../models/v1/groupe';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { SERVICES } from '../models/helpers/utils.interface';
import { Partner } from '../models/v1/partner';
import { Branche } from '../models/v1/branche';
import { Risk } from '../models/v1/risk';
import { UserManagementService } from './user-management.service';
import { Commission } from '../models/v1/commission';
import { Garantie } from '../models/v1/garantie';
import { CommissionGarantie } from '../models/v1/commissionGarantie';
import { Action, ElementCategorie } from '../models/v1/action';
import { SousGroupe } from '../models/v1/sousGroupe';
import { ElementFormAssurance } from '../models/v1/element-form-assurance';
import { BaremeSante } from '../models/v1/baremeSante';
import { BaremeAssuranceIndividuelle } from '../models/v1/baremeAssuranceIndividuelle';
import { BaremeCredit } from '../models/v1/baremeCredit';
import { BaremeHabitation } from '../models/v1/baremeHabitation';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BaremeVoyage } from '../models/v1/baremeVoyage';
// import { BaremeVoyage } from '../models/v1/BaremeVoyage';

@Injectable({
  providedIn: 'root'
})
export class AssuranceManagementService {
  constructor(
    private dataService: DataService,
    private userManagementService: UserManagementService,
    private httpClient: HttpClient
  ) {
  }

  savePartner(partnerInfo: any): Promise<Partner> {
    return new Promise<Partner>((resolve, reject) => {
      const {
        socialReason,
        legalForm,
        address,
        phoneNumber1,
        phoneNumber2,
        code,
        email,
        logo,
        latitude,
        longitude,
        listAccessoryFeeSchedule,
      } = partnerInfo;
      const partner: Partner = new Partner({
        socialReason,
        legalForm,
        address,
        phoneNumber1,
        phoneNumber2,
        code,
        email,
        logo,
        coords: {
          latitude: latitude,
          longitude: longitude
        },
        listAccessoryFeeSchedule
      });
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies', partner)
        .then(
          (partner: Partner) => {
            resolve(partner);
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  createProcessPartner(partnerInfo: any, userInfo: any, accountInfo: any, businessInfo?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.savePartner(partnerInfo)
        .then(
          (partner) => {
            userInfo.partnerId = partner?.id;
            this.userManagementService
              .createUser(userInfo, accountInfo, businessInfo)
              .then(
                (user: any) => {
                  resolve('successful')
                },
                (err: any) => {
                  this.dataService
                    .delete(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies', partner?.id)
                    .then(
                      (res: any) => {
                        reject(err)
                      },
                      (err: Error) => reject(err)
                    )
                }
              )
          }
        )
    })
  }

  getAllPartners(): Promise<Partner[]> {
    return new Promise<Partner[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies')
        .then(
          (partners: Partner[]) => {
            resolve(partners);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllPromotions(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'promotions')
        .then(
          (partners: any[]) => {
            resolve(partners);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getPartnerById(partnerId: string): Promise<Partner> {
    return new Promise<Partner>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies/' + partnerId + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "branches"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (partner: Partner) => {
            resolve(partner);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getPartnerByIdBranches(compagnieId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnie-branche/compagnie/' + compagnieId)
        .then(
          (partner: any[]) => {
            resolve(partner);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getPromotionById(partnerId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'promotions/' + partnerId)
        .then(
          (partner: any) => {
            resolve(partner);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  patchPartner(partnerId: string, partner: Partner): Promise<Partner> {
    return new Promise<Partner>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies', partnerId, partner)
        .then(
          (partner: Partner) => {
            resolve(partner);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBranches(): Promise<Branche[]> {
    return new Promise<Branche[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `branches?filter={
          "include":[
            "compagnies"
          ]
      }`)
        .then(
          (branches: Branche[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBranchesByPageAndLimit(limit = 10, page = 0, like = ''): Promise<Branche[]> {
    const tax = like.length && Number.isInteger(Number(like)) ? `,{"tax": ${Number(like)}}` : "";
    return new Promise<Branche[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `branches?filter={
          "include":[
            {
              "relation": "compagnies",
              "scope":{
                "fields":["socialReason", "id"]
              }
            }
          ],
          "where": {"or":[{"libelle": {"like": "${like}"} },{"description": {"like": "${like}"}} ${tax}]},
          "limit": ${limit},
          "skip": ${page * limit}
      }`)
        .then(
          (branches: Branche[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getCountBranches(like = ''): Promise<{ count: number }> {
    const tax = like.length && Number.isInteger(Number(like)) ? `,{"tax": ${Number(like)}}` : "";
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `branches/count?where= {"or":[{"libelle": {"like": "${like}"} },{"description": {"like": "${like}"}} ${tax}]}`
      )
        .then(
          (branches: { count: number }) => {
            resolve(branches);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBranchesWithoutRelation(): Promise<Branche[]> {
    return new Promise<Branche[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches')
        .then(
          (branches: Branche[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBranchesByCompagnie(): Promise<Branche[]> {

    var filter = { where: { n: { gt: 1 } }, skip: 1, fields: ['n'] };
    return new Promise<Branche[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "compagnies"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (branches: Branche[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  findAllBranches(): Promise<Branche[]> {
    return new Promise<Branche[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches')
        .then(
          (branches: Branche[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  findAllCompagnieBranches(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnie-branches')
        .then(
          (branches: any[]) => {
            resolve(branches);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllFormsByBranche(idBranche): Promise<ElementFormAssurance[]> {
    return new Promise<ElementFormAssurance[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches/' + idBranche + '/element-forms?filter={"where": {"isDeleted":{"neq":true}}}')
        .then(
          (elementForms: ElementFormAssurance[]) => {
            resolve(elementForms);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllRisquesByBranche(idBranche: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches/' + idBranche + '/risques')
        .then(
          (risques: any[]) => {
            resolve(risques);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllRisques(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'risques')
        .then(
          (risques: any[]) => {
            resolve(risques);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllGarantiesByRisque(idRisque): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'risques/' + idRisque + '/garanties')
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllCompagnieByBranche(idBranche): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches/' + idBranche + '/compagnies')
        .then(
          (compagnies: any[]) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  getAllCompagnieByRisque(idRisques): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'risques/' + idRisques + '/compagnies')
        .then(
          (compagnies: any[]) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllCompagnie(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies')
        .then(
          (compagnies: any[]) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }
  getAllCompagnies(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'compagnies')
        .then(
          (compagnies: any[]) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getTarifByGarantieBrancheRisqueCompagnieAndEform(garantie, idBranche, idRisque, idCompagnie, elementForms): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties/tarif/${garantie}/${idBranche}/${idRisque}/${idCompagnie}/[${JSON.stringify(elementForms)}]`)
        .then(
          (data: any[]) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getTarifByRisqueCompagnieAndEform(idRisque, idCompagnie, elementForms): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties/tarif/${idRisque}/${idCompagnie}/${JSON.stringify(elementForms)}`)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getBasicPriceByRisqueAndCompagnieAndFormsElements(idRisque: string, idCompagnie: string, elementForms: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `tarification-risque/tarif/basic-price/${idRisque}/${idCompagnie}`, { elementsForms: elementForms })
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  savePolice(police): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'police', police)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  updatePolice(assurance, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'police', assurance.id, data)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  cancelPolice(assurance, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'police', assurance.id, data)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  saveElementFormValues(elementForm): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'element-form-values', elementForm)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  savePoliceGarantieRelations(garanties): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'police-garantie-relations', garanties)
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getNumberOfSubscriptions() {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police/count')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getNumberOfSubscriptionsGroupe() {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police/count?where={"clientId": { "exists": false } }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllAssurancesAndUsersByCreated(userId, limit, page): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndUsers?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}], "where":{"createdBy":"' + userId + '", "order": "date DESC" , "limit" : "' + limit + '", "skip": "' + (page * limit) + '" }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  getAssuranceByPoliceNumber(policeNumber): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndUsers?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}], "where":{"policeNumber":"' + policeNumber + '" }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllAssurancesByCreated(userId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police?filter={"include": [{"relation": "branche"}], "where":{"createdBy":"' + userId + '",  }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }
  /**
   *
        this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
          'police?filter={"include": [{"relation": "branche"}] , "where":{ "clientId": { "exists": true } }, "limit" : 10, "skip": 0 }')
   */
  getAllAssurancesAndUsers(limit, page): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndUsers?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}] ,  "limit" : "' + limit + '", "skip": "' + (page * limit) + '", "order" : ["date DESC"] }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllAssurancesAndUsersByPoliceNumber(policeNumber): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndUsers?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}] , "where":{ "policeNumber": "' + policeNumber + '"}, "order" : "date DESC" }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  getAllAssurancesAndUsersByElementForm(valeur): Promise<ElementFormAssurance[]> {
    return new Promise<ElementFormAssurance[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `/element-form-values/getAllSubscriptionAndUserByName/${valeur}`)
        .then(
          (elementForms: ElementFormAssurance[]) => {
            resolve(elementForms);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllAssurances(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}]  }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  getAllAssurancesAndUsersGroupes(limit, page): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndEnterprises?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}] , "where":{ "clientId": { "exists": false }}, "limit" : "' + limit + '", "skip": "' + (page * limit) + '", "order" : "date desc" }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }
  getAllAssurancesGroupes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police?filter={"include": [{"relation": "branche"}] , "where":{ "clientId": { "exists": false } } }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllAssurancesAndUsersByCreatedGroupes(userId, limit, page): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'policeAndEnterprises?filter={"include": [{"relation": "branche"}, {"relation": "compagnie"}], "where":{"createdBy":"' + userId + '", "clientId": { "exists": false }}, "limit" : "' + limit + '", "skip": "' + page + '" }')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }
  getAllAssurancesGroupeByCreated(userId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'police?filter={"include": [{"relation": "branche"}], "where":{"createdBy":"' + userId + '", "clientId": { "exists": false } }}')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAssuranceById(assuranceId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'policeAndUsers/' + assuranceId + '?filter={"include": [{"relation": "branche"},{"relation": "compagnie"},{"relation": "garanties"},{"relation": "risque"},{"relation": "elementFormValues", "scope": {"include": [{"relation": "elementForm"}]}}]}')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }


  getBranchById(brancheId: string): Promise<Branche> {
    return new Promise<Branche>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches/' + brancheId + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "compagnies"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (branche: Branche) => {
            resolve(branche);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  deleteBranchById(brancheId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `branches`, brancheId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getAllRisks(): Promise<Risk[]> {
    return new Promise<Risk[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques?filter={
          "include":[
           "branche"
          ]
      }`
      )
        .then(
          (risks: Risk[]) => {
            resolve(risks);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getAllRisksByPageAndLimit(limit = 10, page = 0, like = ''): Promise<Risk[]> {
    const q = like.toLocaleUpperCase()
    return new Promise<Risk[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques?filter={
          "include":[
           {
            "relation":"branche"
           }
          ],
          "where": {"or":[{"libelle": {"like": "${q}"} },{"description": {"like": "${q}"}},{"tarif": {"like": "${q}"}}]},
          "limit": ${limit},
          "skip": ${page * limit}
      }`
      )
        .then(
          (risks: Risk[]) => {
            resolve(risks);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountRisks(like = ''): Promise<{ count: number }> {
    const q = like.toLocaleUpperCase()
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques/count?where={"or":[{"libelle": {"like": "${q}"} },{"description": {"like": "${q}"}},{"tarif": {"like": "${q}"}}]}`
      )
        .then(
          (risks: { count: number }) => {
            resolve(risks);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllRiskByGarantie(garantieId: string): Promise<Risk[]> {
    return new Promise<Risk[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties/${garantieId}/risques`)
        .then(
          (risks: Risk[]) => {
            resolve(risks);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllGarantiesPaginate(limit?: number, page?: number, like = ''): Promise<Garantie[]> {
    const q = like.toLocaleUpperCase();
    let required: any = '';
    let query = '';
    if (q == 'OUI' || q === 'NON') {
      required = q == 'OUI'
    }
    if (required) {
      query = `,{"required": ${required}}`
    }

    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties?filter={"include":["risques"], "where": {"or":[{"libelle": {"like": "${q}"} },{"description": {"like": "${q}"}},{"family": {"like": "${q}"}} ${query}]}, "limit": ${limit}, "skip": ${page * limit}}`)
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getAllGaranties(limit?: number, page?: number): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'garanties')
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  searchAllGarantiesByLibelle(libelle?: string): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties?filter={ "fields": ["libelle"] }`)
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountAllGaranties(like=''): Promise<any> {
    const q = like.toLocaleUpperCase();
    let required: any = '';
    let query = '';
    if (q == 'OUI' || q === 'NON') {
      required = q == 'OUI'
    }
    if (required) {
      query = `,{"required": ${required}}`
    }
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garanties/count?where={"or":[{"libelle": {"like": "${q}"} },{"description": {"like": "${q}"}},{"family": {"like": "${q}"}} ${query}]}`)
        .then(
          (garanties: any) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllGarantieRisqueRelation(): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, '/garanties/risques?filter={ "include": [ { "relation": "garantie"}, { "relation": "risque"} ] }')
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getAllGarantiesCompagnies(limit, page): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'garantie-compagnie-relations?filter={ "include": [ { "relation": "compagnie"}, { "relation": "garantie"} ], "limit" : "' + limit + '", "skip": "' + (page * limit) + '" }')
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountGarantiesCompagnies() {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'garantie-compagnie-relations/count')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getAllGarantiesByCompagniesAndBy(compagnieId, garantieId): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `garantie-compagnie-relations?filter={"include": ["garantie"], "where":{"and":[{"compagnieId": "${compagnieId}"},{"garantieId":"${garantieId}"}]}}`)
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  //garantie-compagnie-relations

  getRiskById(riskId: string): Promise<Risk> {
    return new Promise<Risk>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'risques/' + riskId)
        .then(
          (risk: Risk) => {
            resolve(risk);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  deleteRiskById(riskId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `risques`, riskId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getGarantieById(garantieId: string): Promise<Garantie> {
    return new Promise<Garantie>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'garanties/' + garantieId + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "risques"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (garantie: Garantie) => {
            resolve(garantie);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getGarantieCompagnieById(garantieId: string): Promise<Garantie> {
    return new Promise<Garantie>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'garantie-compagnie-relations/' + garantieId)
        .then(
          (garantie: Garantie) => {
            resolve(garantie);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  deleteGarantieById(garantieId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `garanties`, garantieId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  deleteGarantieCompagnieById(garantieId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `garantie-compagnie-relations`, garantieId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getRisksByBrancheId(id: string): Promise<Risk[]> {
    return new Promise<Risk[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `branches/${id}/risques`)
        .then(
          (risks: Risk[]) => {
            resolve(risks);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getGarantiesByRiskId(id: string): Promise<Garantie[]> {
    return new Promise<Garantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques/${id}/garanties`)
        .then(
          (garanties: Garantie[]) => {
            resolve(garanties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  postCommission(commission: Commission): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'commissions', commission)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  postCommissionGarantie(commissionGarantie: CommissionGarantie): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, 'commission-garantie-relations', commissionGarantie)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCommissionByCompanyId(id: string): Promise<Commission[]> {
    return new Promise<Commission[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `compagnies/${id}/commissions?filter={
        "include": [
          {
            "relation": "accessoirs"
          }
        ]
      }`)
        .then(
          (commissions: Commission[]) => {
            resolve(commissions);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCommissionByCompanyIdRisqueIdBrancheId(idCompagnie: string, idRisque: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `commissions/${idCompagnie}/${idRisque}`)
        .then(
          (commissions: any) => {
            resolve(commissions);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCommissionGarantieByCommissionId(id: string): Promise<CommissionGarantie[]> {
    return new Promise<CommissionGarantie[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `commissions/${id}/commission-garantie-relations`)
        .then(
          (commissionGaranties: CommissionGarantie[]) => {
            resolve(commissionGaranties);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  removeCommissions(commissionId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `commissions`, commissionId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteAccessoire(accessoireId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `accessoirs`, accessoireId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateCommissions(commissionId: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, `commissions`, commissionId, data)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  addAccessoire(data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `accessoirs`, data)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateAccessoire(accessoireId: string, data: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, `accessoirs`, accessoireId, data)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  removeAllCommissionGarantiesByCommission(commissionId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `commissions/${commissionId}/commission-garantie-relations`)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  removeLinkCommissionGarantie(commissionGarantieId: string) {
    return new Promise<void>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `commission-garantie-relations`, commissionGarantieId)
        .then(() => {
          resolve();
        })
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllActMedicals(): Promise<ActMedical[]> {
    return new Promise<ActMedical[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `act-medicals?filter={
        "include": [
          {
            "relation": "prestataireSpecialities"
          }
        ]
      }`)
        .then(
          (actMedicals: ActMedical[]) => {
            resolve(actMedicals);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllQualites(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `qualites`)
        .then(
          (qualites: ActMedical[]) => {
            resolve(qualites);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getBrancheSante(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `branches?filter={"where": {"libelle":"SANTE"}}`)
        .then(
          (qualites: any) => {
            resolve(qualites);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getActMedicalTemplate(fileName: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `files/act-medical-template/download/${fileName}`)
        .then(
          (fileName: any) => {
            resolve(fileName);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  createActMedicalTemplate(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `files/act-medical-template`)
        .then(
          (fileName: any) => {
            resolve(fileName);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCompagnieOfRisque(risqueId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques/${risqueId}/compagnies`)
        .then(
          (compagnies: any) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllPlanSantes(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes?filter={
        "include": [
          {"relation": "compagnie"},
          {"relation": "risque"}
        ]
      }`)
        .then(
          (compagnies: any) => {
            resolve(compagnies);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllPlanSanteById(planId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes/${planId}?filter={
        "include": [
          {"relation": "compagnie"},
          {"relation": "risque"},
          {
            "relation": "garantieSantes", 
            "scope": {
              "include": [
                {"relation": "actMedicals"}
              ]
            }
          },
          {
            "relation": "planSanteQualites", 
            "scope": {
              "include": [
                {"relation": "qualite"}
              ]
            }
          }
        ]
      }`)
        .then(
          (compagnie: any) => {
            resolve(compagnie);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  setPlanSantes(plan: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes`, plan)
        .then(
          (plan: any) => {
            resolve(plan);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updatePlanSantes(planId: string, plan: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes`, planId, plan)
        .then(
          (plan: any) => {
            resolve(plan);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  setGarantieSante(garantie: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `garantie-santes`, garantie)
        .then(
          (garantie: any) => {
            resolve(garantie);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  setGarantieSanteActeMedical(garantieActe: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `garantie-sante-acte-medicals`, garantieActe)
        .then(
          (garantieActe: any) => {
            resolve(garantieActe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  setQualiteSante(qualite: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes/${qualite?.planSanteId}/plan-sante-qualites`, qualite)
        .then(
          (qualite: any) => {
            resolve(qualite);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  removeAllQualiteSante(planSanteId: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes/${planSanteId}/plan-sante-qualites`)
        .then(
          (qualite: any) => {
            resolve(qualite);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  removeAllGarantieSante(planSanteId: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, `plan-santes/${planSanteId}/garantie-santes`)
        .then(
          (qualite: any) => {
            resolve(qualite);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getRisquesSante(idBranche: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `risques?filter={"where": {"brancheId":"${idBranche}"}}`)
        .then(
          (risques: any) => {
            resolve(risques);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  uploadGarantieActMedical(file: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.upload(SERVICES.ASSURANCE_MANAGEMENT, `files/garantie-act-medical`, file)
        .then(
          (actmedical: any) => {
            resolve(actmedical);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllPlans(limit: number = 10, page: number = 0): Promise<ActMedical[]> {
    return new Promise<ActMedical[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `plan?filter={
        "include": [
          {
            "relation": "compagnie"
          },
          {
            "relation": "branche"
          },
          {
            "relation": "risque"
          }
        ],
        "skip": ${page * limit},
        "limit": ${limit}
      }`)
        .then(
          (actMedicals: ActMedical[]) => {
            resolve(actMedicals);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllCountPlans(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'plan/count')
        .then(
          (actMedicals: any) => {
            resolve(actMedicals);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllPlanById(actMedicalId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'plan/' + actMedicalId + `?filter={
        "include": [
          {
            "relation": "compagnie"
          },
          {
            "relation": "branche"
          },
          {
            "relation": "risque"
          }
        ]
      }`)
        .then(
          (actMedicals: ActMedical[]) => {
            resolve(actMedicals);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getActMedicalById(actMedicalId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'act-medicals/' + actMedicalId + `?filter={
        "include": [
          {
            "relation": "prestataireSpecialities"
          }
        ]
      }`)
        .then(
          (actMedical: any) => {
            resolve(actMedical);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllGroupes(): Promise<Groupe[]> {
    return new Promise<Groupe[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'groupes')
        .then(
          (groupes: Groupe[]) => {
            resolve(groupes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getGroupeById(groupeId: string): Promise<Groupe> {
    return new Promise<Groupe>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'groupes/' + groupeId)
        .then(
          (groupe: Groupe) => {
            resolve(groupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  getAllSousGroupes(): Promise<SousGroupe[]> {
    return new Promise<SousGroupe[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'sous-groupes' + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "groupe"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (sousGroupes: SousGroupe[]) => {
            resolve(sousGroupes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllSpecialities(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'prestataire-specialities' + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "actMedical"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (specialities: any[]) => {
            resolve(specialities);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getSousGroupeById(sousGroupeId: string): Promise<SousGroupe> {
    return new Promise<SousGroupe>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'sous-groupes/' + sousGroupeId + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "groupe"\n' +
        '    }\n' +
        '  ]\n' +
        '}')
        .then(
          (sousGroupe: SousGroupe) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  getSpecialityById(sousGroupeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'prestataire-specialities/' + sousGroupeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }
  deleteSpecialityById(sousGroupeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'prestataire-specialities/' + sousGroupeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  deleteActeById(sousGroupeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'act-medicals/' + sousGroupeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }
  deletePlanById(sousGroupeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'plan/' + sousGroupeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  deletePromotionById(sousGroupeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.USER_MANAGEMENT, 'promotions/' + sousGroupeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  deletePoliceById(policeId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, '/police/' + policeId)
        .then(
          (sousGroupe: any) => {
            resolve(sousGroupe);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  getAllMedicaments(): Promise<Medicament[]> {
    return new Promise<Medicament[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'medicaments')
        .then(
          (medicaments: Medicament[]) => {
            resolve(medicaments);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getMedicamentById(medicamentId: string): Promise<Medicament> {
    return new Promise<Medicament>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'medicaments/' + medicamentId)
        .then(
          (medicament: Medicament) => {
            resolve(medicament);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    });
  }

  getElementFormByBranche(brancheId: string): Promise<Formulaire<any>[]> {
    return new Promise<Formulaire<any>[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'branches/' + brancheId + '/element-forms?filter={"where": {"isDeleted":{"neq":true}}}')
        .then(
          (element_forms: Formulaire<any>[]) => {
            resolve(element_forms);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getElementFormById(id: string): Promise<Formulaire<any>[]> {
    return new Promise<Formulaire<any>[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `/element-forms/${id}`)
        .then(
          (element_forms: Formulaire<any>[]) => {
            resolve(element_forms);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getAllBaremesAutomobileByCompany(limit, page, company): Promise<BaremeAutomobile[]> {
    return new Promise<BaremeAutomobile[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-automobiles' + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "compagnie"\n' +
        '    }\n' +
        '  ]\n' +
        ', "where": {"compagnieId":"' + company + '"}' +
        ', "limit" : "' + limit + '", "skip": "' + (page * limit) + '"}')

        // 'police?filter={"include": [{"relation": "branche"}] , "where":{ "clientId": { "exists": true } }, "limit" : 10, "skip": 0 }')
        .then(
          (baremeAutomobiles: BaremeAutomobile[]) => {
            resolve(baremeAutomobiles);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getAllBaremesAutomobile(limit, page): Promise<BaremeAutomobile[]> {
    return new Promise<BaremeAutomobile[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-automobiles' + '?filter={\n' +
        '  "include": [\n' +
        '    {\n' +
        '      "relation": "compagnie"\n' +
        '    }\n' +
        '  ]\n' +
        ', "limit" : "' + limit + '", "skip": "' + (page * limit) + '"}')

        // 'police?filter={"include": [{"relation": "branche"}] , "where":{ "clientId": { "exists": true } }, "limit" : 10, "skip": 0 }')
        .then(
          (baremeAutomobiles: BaremeAutomobile[]) => {
            resolve(baremeAutomobiles);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesAutomobileFiltered(limit = 10, page = 0, payload: any): Promise<BaremeAutomobile[]> {
    return new Promise<BaremeAutomobile[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-automobiles?filter={
          "where":  ${payload},
          "limit": ${limit},
          "skip": ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremeAutomobiles: BaremeAutomobile[]) => {
            resolve(baremeAutomobiles);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesAutomobileFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-automobiles/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremeAutomobiles() {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'bareme-automobiles/count')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  getCountBaremeAutomobilesByCompany(company) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        'bareme-automobiles/count?where={"compagnieId":"' + company + '"}')
        .then(
          (data: any) => {
            resolve(data);
          }
        )
        .catch(
          (err) => {
            console.log(err);
          }
        )
    })
  }

  updateBaremeAutomobile(id, data): Promise<BaremeAutomobile> {
    return new Promise<BaremeAutomobile>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-automobiles', id, data)
        .then(
          (baremeAutomobile: BaremeAutomobile) => {
            resolve(baremeAutomobile);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteBaremeAutomobile(id): Promise<BaremeAutomobile> {
    return new Promise<BaremeAutomobile>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-automobiles', id)
        .then(
          (baremeAutomobile: BaremeAutomobile) => {
            resolve(baremeAutomobile);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesSante(limit = 10, page = 0): Promise<BaremeSante[]> {
    return new Promise<BaremeSante[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-santes?filter={
        "limit": ${limit},
        "skip": ${page * limit},
        "include": [
          "compagnie"
        ]
       }`
      )
        .then(
          (baremeSantes: BaremeSante[]) => {
            resolve(baremeSantes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }
  getAllCoutBaremesSante(): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-santes/count')
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }



  updateBaremeSante(id, data): Promise<BaremeSante> {
    return new Promise<BaremeSante>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-santes', id, data)
        .then(
          (baremeSante: BaremeSante) => {
            resolve(baremeSante);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesSanteFiltered(limit = 10, page = 0, payload: any): Promise<BaremeSante[]> {
    return new Promise<BaremeSante[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-santes?filter={
          "where":  ${payload},
          "limit": ${limit},
          "skip": ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeSante[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesSanteFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-santes/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  updateAllBaremeSante(data): Promise<BaremeSante> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-sante?where={"libelle": "Bareme Sante"}',
        data
      )
        .subscribe(
          (baremeSante: any) => {
            resolve(baremeSante);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }
  getBaremeSanteByLimit(): Promise<BaremeSante[]> {
    return new Promise<BaremeSante[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-santes?filter={"limit":1}`
      )
        .then(
          (baremeSantes: BaremeSante[]) => {
            resolve(baremeSantes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateAllBaremeHabitation(data): Promise<BaremeHabitation> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-habitations?where={"libelle": "Bareme Habitation"}',
        data
      )
        .subscribe(
          (baremeSante: any) => {
            resolve(baremeSante);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }

  getBaremeHabitationByLimit(): Promise<BaremeHabitation[]> {
    return new Promise<BaremeHabitation[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-habitations?filter={"limit":1}`
      )
        .then(
          (baremeSantes: BaremeHabitation[]) => {
            resolve(baremeSantes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getAllBaremesHabitationFiltered(limit = 10, page = 0, payload: any): Promise<BaremeHabitation[]> {
    return new Promise<BaremeHabitation[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-habitations?filter={
          "where":  ${payload},
          "limit": ${limit},
          "skip": ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeHabitation[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesHabitationFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-habitations/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateAllBaremeAutomobile(data): Promise<BaremeAutomobile> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-automobiles?where={"libelle": "Bareme Automobile"}',
        data
      )
        .subscribe(
          (baremeAuto: any) => {
            resolve(baremeAuto);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }

  getBaremeAutomobileByLimit(): Promise<BaremeAutomobile[]> {
    return new Promise<BaremeAutomobile[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-automobiles?filter={"limit":1}`
      )
        .then(
          (baremeAuto: BaremeAutomobile[]) => {
            resolve(baremeAuto);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateAllBaremeVoyage(data): Promise<BaremeVoyage> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-voyages?where={"libelle": "Bareme Voyage"}',
        data
      )
        .subscribe(
          (baremeVoyage: any) => {
            resolve(baremeVoyage);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }

  getBaremeBaremeVoyageByLimit(): Promise<BaremeVoyage[]> {
    return new Promise<BaremeVoyage[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-voyages?filter={"limit":1}`
      )
        .then(
          (baremeVoyaye: BaremeVoyage[]) => {
            resolve(baremeVoyaye);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesVoyageFiltered(limit = 10, page = 0, payload: any): Promise<BaremeHabitation[]> {
    return new Promise<BaremeHabitation[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-voyages?filter={
          "where":  ${payload},
          "limit": ${limit},
          "skip": ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeHabitation[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesVoyageFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-voyages/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  updateAllBaremeAssuranceVieIndividuelle(data): Promise<BaremeAssuranceIndividuelle> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-assurance-individuelles?where={"libelle": "Bareme Assurance individuelle"}',
        data
      )
        .subscribe(
          (baremeAssuranceVie: any) => {
            resolve(baremeAssuranceVie);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }


  getAllBaremesAssuranceVieFiltered(limit = 10, page = 0, payload: any): Promise<BaremeAssuranceIndividuelle[]> {
    return new Promise<BaremeAssuranceIndividuelle[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-assurance-individuelles?filter={
          "where":  ${payload},
          "limit": ${limit},
          "skip": ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeAssuranceIndividuelle[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesAssuranceVieFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-assurance-individuelles/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getBaremeBaremeAssuranceVieIndividuelleByLimit(): Promise<BaremeAssuranceIndividuelle[]> {
    return new Promise<BaremeAssuranceIndividuelle[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-assurance-individuelles?filter={"limit":1}`
      )
        .then(
          (baremeAssuranceVie: BaremeAssuranceIndividuelle[]) => {
            resolve(baremeAssuranceVie);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateAllBaremeCredit(data): Promise<BaremeCredit> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        'bareme-credits?where={"libelle": "Bareme Credit"}',
        data
      )
        .subscribe(
          (baremeCredit: any) => {
            resolve(baremeCredit);
          },
          (err: any) => {
            reject(err);
          }
        )

    })
  }

  getBaremeCreditByLimit(): Promise<BaremeCredit[]> {
    return new Promise<BaremeCredit[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-credits?filter={"limit":1}`
      )
        .then(
          (baremeCredit: BaremeCredit[]) => {
            resolve(baremeCredit);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesCreditFiltered(limit = 10, page = 0, payload: any): Promise<BaremeAssuranceIndividuelle[]> {
    return new Promise<BaremeAssuranceIndividuelle[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-credits?filter={
          "where":  ${payload},
          "limit":  ${limit},
          "skip":   ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeAssuranceIndividuelle[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesCreditFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-credits/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getBaremeBureauxByLimit(): Promise<BaremeCredit[]> {
    return new Promise<BaremeCredit[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-bureaux?filter={"limit":1}`
      )
        .then(
          (baremeCredit: BaremeCredit[]) => {
            resolve(baremeCredit);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesBureauxFiltered(limit = 10, page = 0, payload: any): Promise<BaremeCredit[]> {
    return new Promise<BaremeCredit[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-bureaux?filter={
          "where":  ${payload},
          "limit":  ${limit},
          "skip":   ${page * limit},
          "include": [
            "compagnie"
          ]
      }`
      )
        .then(
          (baremes: BaremeAssuranceIndividuelle[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBureauxCreditFiltered(payload: any): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-bureaux/count?where=${payload}`
      )
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllTarifications(limit: number, page: number): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque?filter={
               "include":[
                  {
                    "relation": "risque",
                    "scope":{
                      "include":[
                        {
                          "relation":"branche"
                        }
                      ] 
                    }
                  }
               ],
               "limit": ${limit},
               "skip": ${page * limit}
        }`

      )
        .then(
          (tarif: any[]) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountAllTarifications(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque/count`
      )
        .then(
          (tarif: any) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateTarificationRisque(tarificationId: string, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque`, tarificationId,
        data
      )
        .then(
          (tarif: any) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getTarificationByRisque(risqueId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque/risques/${risqueId}`,
      )
        .then(
          (tarif: any) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteTarificationByRisque(risqueId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque/${risqueId}`,
      )
        .then(
          (tarif: any) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  createTarification(data: any): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.post(SERVICES.ASSURANCE_MANAGEMENT,
        `tarification-risque`,
        data
      )
        .then(
          (tarif: any) => {
            resolve(tarif);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }



  deleteBaremeSante(id): Promise<BaremeSante> {
    return new Promise<BaremeSante>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-santes', id)
        .then(
          (baremeSante: BaremeSante) => {
            resolve(baremeSante);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesVoyage(limit = 10, page = 0): Promise<any[]> { // todo type barameVoyages
    return new Promise<any[]>((resolve, reject) => { // todo type barameVoyages
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-voyages?filter={
          "include":[
            {
              "relation":"compagnie"
            }
          ],
          "limit": ${limit},
          "skip": ${page * limit}
      }`)
        .then(
          (baremeVoyages: any[]) => { // todo type barameVoyages
            resolve(baremeVoyages);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesVoyage(): Promise<{ count: number }> { // todo type barameVoyages
    return new Promise<{ count: number }>((resolve, reject) => { // todo type barameVoyages
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-voyages/count')
        .then(
          (result: { count: number }) => { // todo type barameVoyages
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateBaremeVoyage(id, data): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-voyages', id, data)
        .then(
          (baremeVoyage: any) => {
            resolve(baremeVoyage);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteBaremeVoyage(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-voyages', id)
        .then(
          (baremeVoyage: any) => {
            resolve(baremeVoyage);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  getCountBaremesAssuranceIndividuelle(): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-assurance-individuelles/count')
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesAssuranceIndividuelle(limit = 10, page = 0): Promise<BaremeAssuranceIndividuelle[]> {
    return new Promise<BaremeAssuranceIndividuelle[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-assurance-individuelles?filter={
          "include":[
            {
              "relation":"compagnie"
            }
          ],
          "limit": ${limit},
          "skip": ${page * limit}
      }`)
        .then(
          (baremeAssuranceIndividuelles: BaremeAssuranceIndividuelle[]) => {
            resolve(baremeAssuranceIndividuelles);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateBaremeAssuranceIndividuelle(id, data): Promise<BaremeAssuranceIndividuelle> {
    return new Promise<BaremeAssuranceIndividuelle>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-assurance-individuelles', id, data)
        .then(
          (baremeAssuranceIndividuelle: BaremeAssuranceIndividuelle) => {
            resolve(baremeAssuranceIndividuelle);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteBaremeAssuranceIndividuelle(id): Promise<BaremeAssuranceIndividuelle> {
    return new Promise<BaremeAssuranceIndividuelle>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-assurance-individuelles', id)
        .then(
          (baremeAssuranceIndividuelle: BaremeAssuranceIndividuelle) => {
            resolve(baremeAssuranceIndividuelle);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesCredit(limit = 10, page = 0): Promise<BaremeCredit[]> {
    return new Promise<BaremeCredit[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-credits?filter={
          "include":[
            {
              "relation": "compagnie"
            }
          ],
          "limit": ${limit},
          "skip": ${page * limit}
      }`)
        .then(
          (baremes: BaremeCredit[]) => {
            resolve(baremes);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getCountBaremesCredit(): Promise<{ count: number }> {
    return new Promise<{ count: number }>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `bareme-credits/count`)
        .then(
          (result: { count: number }) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  updateBaremeCredit(id, data): Promise<BaremeCredit> {
    return new Promise<BaremeCredit>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-credits', id, data)
        .then(
          (baremeCredit: BaremeCredit) => {
            resolve(baremeCredit);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteBaremeCredit(id): Promise<BaremeCredit> {
    return new Promise<BaremeCredit>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-credits', id)
        .then(
          (baremeCredit: BaremeCredit) => {
            resolve(baremeCredit);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllBaremesHabitation(limit = 10, page = 0): Promise<BaremeHabitation[]> {
    return new Promise<BaremeHabitation[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-habitations?filter={
            "include": [
            {
              "relation":"compagnie"
             }
            ],
          "limit": ${limit},
          "skip":${page * limit}
        }`
      )
        .then(
          (baremeHabitations: BaremeHabitation[]) => {
            resolve(baremeHabitations);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  getAllCountBaremesHabitation(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT,
        `bareme-habitations/count`
      )
        .then(
          (result: any) => {
            resolve(result);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }


  updateBaremeHabitation(id, data): Promise<BaremeHabitation> {
    return new Promise<BaremeHabitation>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-habitations', id, data)
        .then(
          (baremeHabitation: BaremeHabitation) => {
            resolve(baremeHabitation);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  deleteBaremeHabitation(id): Promise<BaremeHabitation> {
    return new Promise<BaremeHabitation>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'bareme-habitations', id)
        .then(
          (baremeHabitation: BaremeHabitation) => {
            resolve(baremeHabitation);
          }
        )
        .catch(
          (err: any) => {
            reject(err);
          }
        )
    })
  }

  async addConditionGeneraleBranche(compagnieBrancheId: string, data: object) {
    try {
      const response = await this.dataService.patch(
        SERVICES.ASSURANCE_MANAGEMENT,
        'compagnie-branche/add-general-condition',
        compagnieBrancheId,
        data
      );
      console.log("response", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  updateGarantie(garantieId: string, data: any) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        `garanties?where={ "id": "${garantieId}" }`,
        data
      ).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      )
    })
  }


  deletePlanIdOnGarantie(planId: string) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.patch(environment.services["ASSURANCE_MANAGEMENT"] +
        `garanties?where={ "planId": "${planId}" }`,
        {
          "planId": ""
        }
      ).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      )
    })
  }


  async getConditionParticuliereRisque(risqueId: string) {
    try {
      const response = await this.dataService.get(
        SERVICES.ASSURANCE_MANAGEMENT,
        `condition-particuliere/risques/${risqueId}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async addConditionParticuliereRisque(data: { nom: string, contenu: string, risqueId: string }) {
    try {
      const response = await this.dataService.post(
        SERVICES.ASSURANCE_MANAGEMENT,
        `condition-particuliere`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateConditionParticuliereRisque(conditionId: string, data: { nom: string, contenu: string, risqueId: string }) {
    try {
      const response = await this.dataService.patch(
        SERVICES.ASSURANCE_MANAGEMENT,
        `condition-particuliere`,
        conditionId,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  }


  async getRelanceConfigures(limit = 10, page = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `relance?filter={
            "where":{
                "isExecuted": { "neq": false }
            },
            "include":["relanceType"],
            "limit": ${limit},
            "skip": ${limit * page}
        }`
      ).then(data => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

  async getRelanceCount(filter = { "where": {} }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `relance/count?filter=${JSON.stringify(filter)}`
      ).then(data => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }


  async getRelanceImpayes(limit = 10, page = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `relance/impayes?filter={
            "include":["relanceType"],
            "limit": ${limit},
            "skip": ${limit * page}
        }`
      ).then(data => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

  async getRelanceEnvoye(limit = 10, page = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `relance?filter={
            "where":{
              "isExecuted": true
          },
            "include":["relanceType"],
            "limit": ${limit},
            "skip": ${limit * page}
        }`
      ).then(data => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

  async getRelanceArrieres(limit = 10, page = 0): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `relance/arrieres?filter={
            "include":["relanceType"],
            "limit": ${limit},
            "skip": ${limit * page}
        }`
      ).then(data => {
        resolve(data)
      }, (err) => {
        reject(err)
      })
    })
  }

}
