import { AuthService } from 'app/shared/auth/auth.service';
import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import {ROUTES} from './vertical-menu-routes.config';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.css'],
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  showLogo = false;
  isLoading = true;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private authService: AuthService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  async ngOnInit() {
    this.menuItems = ROUTES;
    const canReadSinitres = await this.authService.hasPermission('READ_SINISTRES')
    const canReadUsers = await this.authService.hasPermission('READ_USERS')
    const canReadRoles = await this.authService.hasPermission('READ_ROLES')
    const canReadProfil = await this.authService.hasPermission('READ_PROFIL')
    const canReadHealthCare = await this.authService.hasPermission('READ_HEALTH_CARES')
    const canReadActMedicaux = await this.authService.hasPermission('READ_ACT_MEDICAUX')
    const canActivateAccounPrestataire = await this.authService.hasPermission('ACTIVATE_ACCOUNT_PRESTATAIRE')
    const canReadPrestationsPrestataire = await this.authService.hasPermission('READ_PRESTATIONS_PRESTATAIRE')
    const canReadDevisPrestataire = await this.authService.hasPermission('READ_DEVIS_PRESTATAIRE')
    const canReadPlan = await this.authService.hasPermission('READ_PLANS')
    const canReadGestionBordereaux = await this.authService.hasPermission('GESTION_BORDEREAUX')
    const canReadClotureBordereaux = await this.authService.hasPermission('CLOTURE_BORDEREAUX')
    const canReadPaymentBordereaux = await this.authService.hasPermission('PAYMENT_BORDEREAUX')
    const canReadSubscriptionIndividual = await this.authService.hasPermission('READ_SUBSCRIPTION_INDIVIDUAL')
    const canReadFreeRelease = await this.authService.hasPermission('READ_FREE_RELEASE')
    const canAddSubscriptionIndividual = await this.authService.hasPermission('ADD_SUBSCRIPTION_INDIVIDUAL')
    const canReadPersonIndividual = await this.authService.hasPermission('READ_PERSON_INDIVIDUAL')
    const canReadSubscriptionGroup = await this.authService.hasPermission('READ_SUBSCRIPTION_GROUP')
    const canAddSubscriptionGroup = await this.authService.hasPermission('ADD_SUBSCRIPTION_GROUP')
    const canReadPersonMoral = await this.authService.hasPermission('READ_PERSON_MORAL')
    const canReadTransactions = await this.authService.hasPermission('READ_TRANSACTIONS')
    const canReadPromotions = await this.authService.hasPermission('READ_PROMOTIONS')
    const canAddRelanceEcheance = await this.authService.hasPermission('ADD_RELANCE_ECHEANCE')
    const canCreateCertificate = await this.authService.hasPermission('CREATE_CERTIFICATE')
    const canReadCertificate = await this.authService.hasPermission('READ_CERTIFICATE')
    const canReadUsedCertificate = await this.authService.hasPermission('READ_CERTIFICATE')
    const canReadUnusedCertificate = await this.authService.hasPermission('READ_CERTIFICATE')
    const canReadBrokers = await this.authService.hasPermission('READ_BROKERS')
    const canReadDistributors = await this.authService.hasPermission('READ_DISTRIBUTORS')
    const canReadPartners = await this.authService.hasPermission('READ_PARTNERS')
    const canReadBranches = await this.authService.hasPermission('READ_BRANCHES')
    const canReadRisks = await this.authService.hasPermission('READ_RISKS')
    const canReadGaranties = await this.authService.hasPermission('READ_GARANTIES')
    const canReadGarantiesPartners = await this.authService.hasPermission('READ_GARANTIES_PARTNERS')
    const canReadRatesAuto = await this.authService.hasPermission('READ_RATES_AUTO')
    const canReadHealthScales = await this.authService.hasPermission('READ_HEALTH_SCALES')
    const canReadTravelScales = await this.authService.hasPermission('READ_TRAVEL_SCALES')
    const canReadCreditRates = await this.authService.hasPermission('READ_CREDIT_RATES')
    const canReadHousingRates = await this.authService.hasPermission('READ_HOUSING_RATES')
    const canReadIndividualInsuranceRates = await this.authService.hasPermission('READ_INDIVIDUAL_INSURANCE_RATES')
    const canReadTarification = await this.authService.hasPermission('READ_TARIFICATION')
    const canReadEformulaire = await this.authService.hasPermission('READ_E_FORMULAIRE')
    const canReadConditionParticuliere = await this.authService.hasPermission('READ_E_FORMULAIRE')

    this.menuItems.forEach(item => {
      if (item.path == '/home/sinisters' && canReadSinitres) {
        item.hidden = true;
      }
      if (item.path == '/home/plans' && canReadPlan) {
        item.hidden = true;
      }
      if (item.path == '/home/condition-particuliere' && canReadConditionParticuliere) {
        item.hidden = true;
      }
      if (item.path == '/home/transactions' && canReadTransactions) {
        item.hidden = true;
      }
      if (item.path == '') {
        item.submenu.forEach((subitem, index) => {

          if (subitem.path == '/home/free-release' && canReadSubscriptionIndividual) {
            subitem.hidden = true;
            item.hidden = true;
          }

          if (subitem.path == '/home/settings/users' && canReadUsers) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/health-care/drug' && canReadHealthCare) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/health-care/medical-act' && canReadActMedicaux) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/commissions' && canReadGestionBordereaux) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/slips' && canReadClotureBordereaux) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/payment-management/slips' && canReadPaymentBordereaux) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/promotion' && canReadPromotions) {
            subitem.hidden = true;
            item.hidden = true;
          }
         
          if (subitem.path == '/home/brokers' && canReadBrokers) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/partner' && canReadPartners) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/branche' && canReadBranches) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/risk' && canReadRisks) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/garanties' && canReadGaranties) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/constants/garanties-compagnies' && canReadGarantiesPartners) {
            subitem.hidden = true;
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/roles' && canReadRoles) {
            subitem.hidden = true;
            item.submenu[index].hidden = true
            item.hidden = true;
          }
          if (subitem.path == '/home/settings/profil' && canReadProfil) {
            subitem.hidden = true;
            item.hidden = true;
          }

          if (subitem.path == '') {
            subitem.submenu.forEach((subsubitem, index) => {
              if (subsubitem.path == '/home/subscriptions' && canReadSubscriptionIndividual) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/subscriptions/add' && canAddSubscriptionIndividual) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/physical-person/list' && canReadPersonIndividual) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/subscription-group/list' && canReadSubscriptionGroup) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/subscription-group/add' && canAddSubscriptionGroup) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/legal-person/list' && canReadPersonMoral) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/certificate/create' && canCreateCertificate) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/certificate/used-certificate' && canReadUsedCertificate) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/certificate/unused-certificate' && canReadUnusedCertificate) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/distributors' && canReadDistributors) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/auto' && canReadRatesAuto) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/sante' && canReadHealthScales) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/voyage' && canReadTravelScales) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/credit' && canReadCreditRates) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/habitation' && canReadHousingRates) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/baremes/assurance-individuelle' && canReadIndividualInsuranceRates) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/tarif' && canReadTarification) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/settings/constants/element-form' && canReadEformulaire) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subitem.path == '/home/settings/roles' && canReadRoles) {
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subitem.path == '/home/settings/profil' && canReadProfil) {
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/prestataire/activate-account' && canActivateAccounPrestataire) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/prestataire/prestations' && canReadPrestationsPrestataire) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/prestataire/devis' && canReadDevisPrestataire) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
             
              if (subsubitem.path == '/home/relance' && canAddRelanceEcheance) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/relance/list' && canAddRelanceEcheance) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/relance/impayes' && canAddRelanceEcheance) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/relance/arrieres' && canAddRelanceEcheance) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
              if (subsubitem.path == '/home/relance/envoyes' && canAddRelanceEcheance) {
                subsubitem.hidden = true;
                subitem.hidden = true;
                item.hidden = true;
              }
            })
          }
        });
      }
    });
    this.isLoading = false;
  }

  ngAfterViewInit() {


    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {
    this.menuItems = ROUTES;

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      this.menuItems = ROUTES;
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});
    this.showLogo = !this.showLogo
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
