import {NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSpinnerModule} from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
 
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {ToastrModule} from 'ngx-toastr';
import {TokenInterceptor} from './shared/auth/token.interceptor';
import {DataService} from './shared/data/data.service';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MomentModule} from 'angular2-moment';
import {FormulaireService} from './shared/data/formulaire.service';
import {FormulaireControlService} from './shared/data/formulaire-control.service';
import {UserManagementService} from './shared/request/user-management.service';
import { RoleManagementService } from './shared/request/role-management.service';
import { ReleaseManagementService } from './shared/request/release-management.service';
import {AssuranceManagementService} from './shared/request/assurance-management.service';
import {CustomizeSwalService} from './shared/services/costomize-swal.service';
import {ErrorInterceptor} from './shared/auth/error.interceptor';
import { DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SlipManagementService } from './shared/request/slip-management.service';
import {StatsService} from './shared/services/stats.service';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { FileService } from './shared/request/file.service';
import { HtmlEditorService, ImageService, LinkService, QuickToolbarService, RichTextEditorAllModule, TableService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { environment } from 'environments/environment';


const firebaseConfig = {
  apiKey: 'YOUR_API_KEY', // YOUR_API_KEY
  authDomain: 'YOUR_AUTH_DOMAIN', // YOUR_AUTH_DOMAIN
  databaseURL: 'YOUR_DATABASE_URL', // YOUR_DATABASE_URL
  projectId: 'YOUR_PROJECT_ID', // YOUR_PROJECT_ID
  storageBucket: 'YOUR_STORAGE_BUCKET', // YOUR_STORAGE_BUCKET
  messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', // YOUR_MESSAGING_SENDER_ID
  appId: 'YOUR_APP_ID', // YOUR_APP_ID
  measurementId: 'YOUR_MEASUREMENT_ID' // YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent
    
  ],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    NgbModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }), 
    AgmCoreModule.forRoot({
      apiKey: environment.API_KEY_GOOGLE,
      libraries: ['places'],
      language:'FR',
      region: 'SN'
    }),
    PerfectScrollbarModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    RichTextEditorAllModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AuthService,
    DataService,
    FormulaireService,
    FormulaireControlService,
    UserManagementService,
    RoleManagementService,
    ReleaseManagementService,
    SlipManagementService,
    AssuranceManagementService,
    CustomizeSwalService,
    DecimalPipe,
    StatsService,
    FileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    WINDOW_PROVIDERS,
     ToolbarService, 
     LinkService, 
     ImageService, 
     HtmlEditorService,
     TableService,
     ImageService,    
     QuickToolbarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
