<section id="header-assurance">
  <div class="row">
    <div class="col-12">
      <div class="media align-items-center my-2 mx-3">
        <div class="media-body d-sm-flex justify-content-between align-items-center">
          <div class="media-text mb-2 mb-sm-0">
            <div class="content-header">{{title}}</div>
            <p class="content-sub-header mb-1">{{subtitle}}</p>
          </div>
          <div class="media-link">
            <ng-container *ngFor="let btn of Buttons">
              <button
                type="button"
                (click)="btn.is_fn_back? goBack() : goToUrl(btn.url_redirect)"
                [class]="btn.class">
                <i [class]="btn.icon"></i> {{btn.name}}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
