import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
declare var $: any
interface ToastInput {
  title?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizeSwalService {

  constructor() {
  }

  // alert
  Alert(icon, title, text) {
    swal.fire({
      icon: icon,
      title: title,
      text: text,
      customClass: {
        confirmButton: 'btn btn-success'
      },
    })
  }

  // Confirm Text
  async ConfirmText(icon, title: string, text: string) {
    const confirm = await swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#6ed7da',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'OUI',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    })
    return confirm;
  }

  // Prompt Function Constant
  async PromptFunctionConstant() {
    return new Promise((resolve, reject) => {
      swal.mixin({
        input: 'text',
        confirmButtonText: 'Valider &rarr;',
        showCancelButton: true,
        progressSteps: ['1'],
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false,
      }).queue([{
        title: 'Constant',
        text: 'Entrer le constant'
      }]).then(function (result: any) {
        if (result.value) {
          swal.fire({
            title: 'Vérification!',
            html: 'Valeur: <pre><code>' +
              JSON.stringify(result.value) +
              '</code></pre>',
            confirmButtonText: 'Confirmer!'
          }).then(
            () => resolve(result.value)
          )
        }
      });
    })
  }
}
