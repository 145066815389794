import { Injectable } from '@angular/core';
import { IUser, UserDTO } from '../models/v1/user';
import { DataService } from '../data/data.service';
import { SERVICES } from '../models/helpers/utils.interface';
import { AccountDTO, IAccount } from '../models/v1/account';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  constructor(private dataService: DataService) {
  }

  getAllUserByFirstnameLastname(name): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/byFirstnameAndLastname/${name}`)
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllPrestataires(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/prestataire')
        .then(
          (users: IUser[]) => {
            resolve(users.reverse());
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllPrestatairesByPageAndLimit(pageNum, itemsPerPage): Promise<IUser[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/prestataire/${pageNum}/${itemsPerPage}`)
        .then(
          (users: any[]) => {
            console.log("users ", users)
            resolve(users.reverse());
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersProassur(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/proassur')
        .then(
          (users: IUser[]) => {
            console.log('getAllUsersProassur() users ===== ', users)
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersCompagnies(id): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'users/compagnies/' + id)
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  getAllUsersPrestataire(id): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/byprestataire/' + id)
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  async getAllPrestationCompagnies(id): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `tickets?filter={
            "include": [
                 {"relation": "pieceJointes"},
                 {"relation": "police"},
                 {"relation" : "ticketActMedicalRelations"},
                 {"relation" : "actMedicals"},
                 {"relation" : "ordonance"}
             ],
          "where": {
                  "prestataireId": "${id}"
                }
        }`)
        .then(
          async (responsePrestataire: any) => {
            if (!responsePrestataire) {
              throw new Error("Client non souscrit à une assurance maladie.");
            }
            let data: any = []
            for (let index = 0; index < responsePrestataire.length; index++) {
              let element = responsePrestataire[index];

              await this.dataService.get(SERVICES.USER_MANAGEMENT, `USERS/${element?.police?.clientId}`)
                .then(
                  (responseClient: any) => {
                    const responseClientInfo = responseClient
                    element.police.clientId = responseClientInfo
                  }
                )

              await this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `police/${element?.police?.id}/element-form-values`)
                .then(
                  (responseClient: any) => {
                    const responseMedicalFile = responseClient
                    element.police.medicalFile = responseMedicalFile
                  }
                )

              data.push(element)


            }
            resolve(data);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  async getAllPrestations(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      let clientsId : any = []
      let clientsData : any = []
      let prestatairesId : any = []
      let prestatairesData : any = []
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `tickets?filter={
            "include": [
              {
                 "relation":"police",
                 "scope":{
                    "include":[
                       {
                          "relation":"branche"
                       }
                    ]
                 }
              },
              {
                 "relation":"ordonance",
                 "scope":{
                    "include":[
                       {
                          "relation":"ordonanceMedicaments",
                          "scope":{
                             "include":[
                                {
                                   "relation":"action"
                                }
                             ]
                          }
                       },
                       {
                          "relation":"ordonanceActMedicalRelations",
                          "scope":{
                             "include":[
                                {
                                   "relation":"actMedical"
                                }
                             ]
                          }
                       }
                    ]
                 }
              },
              {
                 "relation":"ticketActMedicalRelations"
              },
              {
                 "relation":"actMedicals"
              }
           ]
        }`)
        .then(
          async (responsePrestataire: any) => {
            if (!responsePrestataire) {
              throw new Error("error");
            }
            let data: any = []
            responsePrestataire.filter(x => {
              if(clientsId.includes(x?.police?.clientId)=== false){
                clientsId.push(x.police?.clientId)
              }
              if(prestatairesId.includes(x.prestataireId) === false){
                prestatairesId.push(x.prestataireId)
              }

            })
            for (let index = 0; index < clientsId.length; index++) {
              let element = clientsId[index];
              try {
                if (element) {
                  await this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/${element}`)
                    .then(
                      async (responseClient: any) => {
                        if (responseClient) {
                          const responseClientInfo = responseClient
                          clientsData.push(responseClientInfo)
                        }

                      }
                    ).catch((err) => {
                      console.log(err)
                    })
                }
              }catch(e){
                console.log(e)
              }
            }

            for (let index = 0; index < prestatairesId.length; index++) {
              let element = prestatairesId[index];
              try {
                if (element) {
                  await this.dataService.get(SERVICES.USER_MANAGEMENT, `Prestataire/${element}`)
                    .then(
                      async (responseClient: any) => {
                        if (responseClient) {
                          const responseClientInfo = responseClient
                          prestatairesData.push(responseClientInfo)
                        }

                      }
                    ).catch((err) => {
                      console.log(err)
                    })
                }
              }catch(e){
                console.log(e)
              }
            }


            for (let index = 0; index < responsePrestataire.length; index++) {
              let element = responsePrestataire[index];
              try {
                if (element?.police?.clientId) {
                  clientsData.filter(x => {
                    if(x._id === element?.police?.clientId){
                      element.police.clientId = x
                    }
                  })


                }
                if(element?.prestataireId){
                  prestatairesData.filter(x => {
                    if(x._id === element?.prestataireId){
                      element.prestataireId = x
                    }
                  })
                }



              } catch (error) {

              }



              data.push(element)


            }

            resolve(data);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  async getAllDevis(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      let clientsId : any = []
      let clientsData : any = []
      let prestatairesId : any = []
      let prestatairesData : any = []
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `tickets?filter={
            "include": [
              {
                 "relation":"police",
                 "scope":{
                    "include":[
                       {
                          "relation":"branche"
                       }
                    ]
                 }
              },
              {
                 "relation":"ordonance",
                 "scope":{
                    "include":[
                       {
                          "relation":"ordonanceMedicaments",
                          "scope":{
                             "include":[
                                {
                                   "relation":"action"
                                }
                             ]
                          }
                       },
                       {
                          "relation":"ordonanceActMedicalRelations",
                          "scope":{
                             "include":[
                                {
                                   "relation":"actMedical"
                                }
                             ]
                          }
                       }
                    ]
                 }
              },
              {
                 "relation":"ticketActMedicalRelations",
                 "scope":{
                    "include":[
                       {
                          "relation":"actMedical"
                       }
                    ]
                  }
              },
              {
                 "relation":"actMedicals"
              }
           ],
           "where": {
            "needValidation": true
          }
        }`)
        .then(
          async (responsePrestataire: any) => {
            if (!responsePrestataire) {
              throw new Error("error");
            }
            let data: any = []
            responsePrestataire.filter(x => {
              if(clientsId.includes(x.police.clientId)=== false){
                clientsId.push(x.police.clientId)
              }
              if(prestatairesId.includes(x.prestataireId) === false){
                prestatairesId.push(x.prestataireId)
              }

            })
            for (let index = 0; index < clientsId.length; index++) {
              let element = clientsId[index];
              try {
                if (element) {
                  await this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/${element}`)
                    .then(
                      async (responseClient: any) => {
                        if (responseClient) {
                          const responseClientInfo = responseClient
                          clientsData.push(responseClientInfo)
                        }

                      }
                    ).catch((err) => {
                      console.log(err)
                    })
                }
              }catch(e){
                console.log(e)
              }
            }

            for (let index = 0; index < prestatairesId.length; index++) {
              let element = prestatairesId[index];
              try {
                if (element) {
                  await this.dataService.get(SERVICES.USER_MANAGEMENT, `Prestataire/${element}`)
                    .then(
                      async (responseClient: any) => {
                        if (responseClient) {
                          const responseClientInfo = responseClient
                          prestatairesData.push(responseClientInfo)
                        }

                      }
                    ).catch((err) => {
                      console.log(err)
                    })
                }
              }catch(e){
                console.log(e)
              }
            }


            for (let index = 0; index < responsePrestataire.length; index++) {
              let element = responsePrestataire[index];
              try {
                if (element?.police?.clientId) {
                  clientsData.filter(x => {
                    if(x._id === element?.police?.clientId){
                      element.police.clientId = x
                    }
                  })


                }
                if(element?.prestataireId){
                  prestatairesData.filter(x => {
                    if(x._id === element?.prestataireId){
                      element.prestataireId = x
                    }
                  })
                }



              } catch (error) {

              }



              data.push(element)


            }

            resolve(data);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersInsured(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/insured')
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  countAllUsersInsured(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/insured/count')
        .then(
          (numberUsers: number) => {
            resolve(numberUsers);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  countAllUsersPrestataire(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/prestataire/count')
        .then(
          (numberUsers: number) => {
            resolve(numberUsers);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersInsuredByPageAndLimit(pageNum, itemsPerPage): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/insured/byPageAndLimit/${pageNum}/${itemsPerPage}`)
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersInsuredAndSubscriptions(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/insuredAndAllSouscription')
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getOneSubscription(subscriptionId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.ASSURANCE_MANAGEMENT, `police/${subscriptionId}`)
        .then(
          (subscription: any) => {
            resolve(subscription);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  updateSubscription(subscriptionId: string, object: any): Promise<any> {
    console.log('updateSubscription() object = ', object)
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, `police`, subscriptionId, object)
        .then(
          (subscription: any) => {
            resolve(subscription);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsers(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users')
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAllUsersExpert(): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Users/expert')
        .then(
          (users: IUser[]) => {
            resolve(users);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  updateUser(userInfo: any, id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Users', id, { "user": userInfo })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }
  saveUser(userInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const {
        firstname,
        lastname,
        address,
        partnerId,
        referralCode,
        children,
        phoneNumber2,
        spouses,
        profession,
        activity,
        formation,
        civility
      } = userInfo;
      const user = {
        firstname,
        lastname,
        address,
        partnerId: partnerId ? partnerId : null,
        referralCode: this.getReferalCode(referralCode, partnerId),
        children,
        phoneNumber2,
        spouses,
        profession,
        activity,
        formation,
        civility
      }
      if (userInfo.referralCode == 'PRESTATAIRE') {
        this.dataService.post(SERVICES.USER_MANAGEMENT, 'Users', { user: userInfo })
          .then(
            (res: any) => {
              resolve(res)
            }
          )
          .catch(
            (err: any) => {
              reject(err)
            }
          )
      } else {
        this.dataService.post(SERVICES.USER_MANAGEMENT, 'Users', { user })
          .then(
            (res: IUser) => {
              resolve(res)
            }
          )
          .catch(
            (err: any) => {
              reject(err)
            }
          )
      }

    })
  }

  getReferalCode(referralCode, partnerId) {
    if (referralCode) {
      return referralCode
    } else {
      return partnerId ? 'PARTENAIRE' : 'PROASSUR'
    }
  }

  saveAccount(accountInfo: any, userId: string, businessInfo?: any): Promise<IAccount> {
    return new Promise<IAccount>((resolve, reject) => {
      const {
        username,
        email,
        phoneNumber,
        address,
        role,
        defaultAccessType
      } = accountInfo;
      const account: AccountDTO = new AccountDTO({
        account: {
          ...(businessInfo?.name !== '' && { businessInfo }),
          username,
          email,
          phoneNumber,
          address,
          user: userId
        },
        defaultAccessType,
        roleName: role
      })
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'Accounts', account)
        .then(
          (res: IAccount) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  createUser(userInfo: any, accountInfo: any, businessInfo?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.saveUser(userInfo)
        .then((userCreated) => {
          this.saveAccount(accountInfo, userCreated?._id, businessInfo)
            .then((accountCreated) => {
              const user = {...userCreated, ...accountCreated }
              resolve(user)
            })
            .catch(
              (err: Error) => {
                // if the account is not correctly created, we delete the user
                this.dataService
                  .delete(SERVICES.USER_MANAGEMENT, 'Users', userCreated?._id)
                  .then(
                    (res: any) => {
                      reject(err)
                    },
                    (err: Error) => reject(err)
                  )
              }
            )
        })
        .catch(
          (err: Error) => {
            reject(err)
          }
        )
    })

  }

  getUserById(id: string): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `Users/${id}`)
        .then(
          (user: IUser) => {
            resolve(user);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  getAccount(): Promise<IAccount> {
    return new Promise<IAccount>((resolve, reject) => {
      const object = JSON.parse(sessionStorage.getItem('currentAccount')) as unknown as IAccount
      const { id } = JSON.parse(sessionStorage.getItem('currentAccount')) as unknown as IAccount;
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Accounts/' + object['account'].id)
        .then(
          (account: IAccount) => {
            resolve(account)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  getAllAssurancesAndUsersByPhoneNumber(phoneNumber): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'Accounts/getAllSubscription/byNumber/' + phoneNumber)
        .then(
          (account: any[]) => {
            resolve(account)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  changePassword(identifiant: string, oldPassword: string, newPassword: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'GrantAccess/credentials/updatepassword',
        {
          identifiant,
          oldPassword,
          newPassword
        })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }



  updateAccount(id: string, accountInfo: any): Promise<any> {
    return new Promise<IAccount>((resolve, reject) => {

      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Accounts', id, accountInfo)
        .then(
          (res: IAccount) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  deactivateAccount(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Accounts/deactivate', id, {})
        .then(
          (user: any) => {
            resolve(user);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  activateAccount(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'Accounts/active', id, {})
        .then(
          (user: any) => {
            resolve(user);
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }
  deletePrestataire(idUser, idPrestataire, idAccount): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.USER_MANAGEMENT, 'Prestataire/' + idPrestataire)
        .then(
          (users: any) => {
            this.dataService.delete(SERVICES.USER_MANAGEMENT, 'Accounts/' + idAccount)
              .then(
                (users: any) => {
                })
              .catch((err: any) => {

              })
            this.dataService.delete(SERVICES.USER_MANAGEMENT, 'Users/' + idUser)
              .then(
                (users: any) => {
                })
              .catch((err: any) => {

              })
            resolve(true)
          }
        )
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  payTicket(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'tickets', id, { "paid": true })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  deleteTicket(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.delete(SERVICES.ASSURANCE_MANAGEMENT, 'tickets', id)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }
  connectKalpay(secretKey: string, accessKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.PAYMENT_URL, '/userservice/grant-access/login/apikey',
        {
          secretKey,
          accessKey
        })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }
  transfertMoney(number: any, amount:any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'Paiements/provider',{
        "amount": amount,
        "receiverPhoneNumber": ""+number

      })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  validateTicket(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'ticket-act-medical-relations', id, { "validated": true })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }
  payMedicament(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'ordonance-medicaments', id, { "paid": true })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }
  payActe(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'ordonance-act-medical-relations', id, { "paid": true })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  validateActe(id: any): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.dataService.patch(SERVICES.ASSURANCE_MANAGEMENT, 'ordonance-act-medical-relations', id, { "validated": true })
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

}
