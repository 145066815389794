import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormulaireControlService} from 'app/shared/data/formulaire-control.service';
import {Formulaire} from 'app/shared/models/v1/formulaire';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FormulaireControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input() formulaires: Formulaire<string>[] = [];
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: FormulaireControlService) {
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formulaires);
    console.log(this.formulaires)
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }

}
