import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

interface ToastInput {
  title?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizeToastrService {
  currentToast = null;

  constructor(public toast: ToastrService) {
  }

  RemoveToastr() {
    this.toast.clear();
  }

  // Success Type
  TypeSuccess(input: ToastInput) {
    this.toast.success(input.message, input.title);
  }

  // Info Type
  TypeInfo(input: ToastInput) {
    this.toast.info(input.message, input.title);
  }

  // Warning Type
  TypeWarning(input: ToastInput) {
    this.toast.warning(input.message);
  }

  // Error Type
  TypeError(input: ToastInput) {
    this.toast.error(input.message, input.title);
  }

  // Position Top Left
  PositionTopLeft(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.info('I do not think that word means what you think it means.', 'Top Left!', {
      positionClass: 'toast-top-left',
    });
  }

  // Position Top Center
  PositionTopCenterSuccess(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.success(input.message, input.title, {
      positionClass: 'toast-top-center'
    });
  }

  PositionTopCenterError(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.error(input.message, input.title, {
      positionClass: 'toast-top-center'
    });
  }

  // Position Top Left
  PositionTopRight(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.info('I do not think that word means what you think it means.', 'Top Right!', {
      positionClass: 'toast-top-right'
    });
  }

  // Position Top Full Width
  PositionTopFullWidthSuccess(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.success(input.message, input.title, {
      positionClass: 'toast-top-full-width'
    });
  }

  // Position Top Full Width
  PositionTopFullWidthError(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.error(input.message, input.title, {
      positionClass: 'toast-top-full-width'
    });
  }

  PositionTopFullWidthWarning(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.warning(input.message, input.title, {
      positionClass: 'toast-top-full-width'
    });
  }

  // Position Botton Left
  PositionBottonLeft(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.info('I do not think that word means what you think it means.', 'Botton Left!', {
      positionClass: 'toast-bottom-left'
    });
  }

  // Position Botton Center
  PositionBottonCenter(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.info('I do not think that word means what you think it means.', 'Botton Center!', {
      positionClass: 'toast-bottom-center'
    });
  }

  // Position Botton Left
  PositionBottonRight(input?: ToastInput) {
    this.RemoveToastr();
    this.toast.info('I do not think that word means what you think it means.', 'Botton Right!', {
      positionClass: 'toast-bottom-right'
    });
  }

  // Position Botton Full Width
  PositionBottonFullWidthInfo(input: ToastInput) {
    this.RemoveToastr();
    this.toast.info(input.message, input.title, {
      positionClass: 'toast-bottom-full-width'
    });
  }

  PositionBottonFullWidthError(input: ToastInput) {
    this.RemoveToastr();
    this.toast.error(input.message, input.title, {
      positionClass: 'toast-bottom-full-width'
    });
  }

  PositionBottonFullWidthWarning(input: ToastInput) {
    this.RemoveToastr();
    this.toast.warning(input.message, input.title, {
      positionClass: 'toast-bottom-full-width'
    });
  }

  // Text Notification
  TextNotification(input?: ToastInput) {
    this.toast.info('Have fun storming the castle!', 'Miracle Max Says');
  }

  // close button
  CloseButton(input?: ToastInput) {
    this.toast.info('Have fun storming the castle!', 'With Close Button', {closeButton: true});
  }

  // Progress bar
  ProgressBar(input?: ToastInput) {
    this.toast.info('Have fun storming the castle!', 'Progress Bar', {'progressBar': true});
  }

  // Clear Toast Button
  ClearToastButton(input?: ToastInput) {
    this.toast.error('Clear itself?<br /><br /><button type="button" class="btn btn-secondary clear">Yes</button>', 'Clear Toast Button', {
      enableHtml: true
    });
  }

  // Immediately remove current toasts without using animation
  ShowRemoveToast(input?: ToastInput) {
    this.currentToast = this.toast.info('Have fun storming the castle!', 'Miracle Max Says');
  }

  RemoveToast() {
    this.toast.remove(this.currentToast ? this.currentToast.toastId : null);
  }

  // Remove current toasts using animation
  ShowClearToast(input?: ToastInput) {
    this.currentToast = this.toast.info('Have fun storming the castle!', 'Miracle Max Says');
  }

  ClearToast() {
    this.toast.clear(this.currentToast ? this.currentToast.toastId : null);
  }

  // Fast Duration
  FastDuration(input?: ToastInput) {
    this.toast.success('Have fun storming the castle!', 'Fast Duration', {
      'easeTime': 500
    });
  }

  // Timeout
  Timeout(input?: ToastInput) {
    this.toast.success('I do not think that word means what you think it means.', 'Timeout!', {
      'timeOut': 5000
    });
  }

  // Sticky
  Sticky(input?: ToastInput) {
    this.toast.info('I do not think that word means what you think it means.', 'Sticky!', {
      'timeOut': 0
    });
  }
}
