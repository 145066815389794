<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="editForm">
  
      <div *ngFor="let formulaire of formulaires" class="form-row">
        <app-dynamic-form-assurance class="element" [formulaire]="formulaire" [form]="form"></app-dynamic-form-assurance>
      </div>
  
      <div class="form-row">
        <button type="button"
                class="btn btn-primary mr-2"
                type="submit" [disabled]="!form.valid"
        >
          <i class="ft-check-square mr-1"></i>Aperçu resultat
        </button>
        <!-- <button type="submit" [disabled]="!form.valid">Save</button> -->
      </div>
    </form>
  
    <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
  </div>