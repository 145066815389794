import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SERVICES } from '../models/helpers/utils.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private baremAutomobileSource = new BehaviorSubject<{}>({});
  baremAutomobileEvent = this.baremAutomobileSource.asObservable();
  objectFilterSource = new BehaviorSubject<{}>({});
  objectFilter = this.objectFilterSource.asObservable();

  headers;
  _searchInput= new Subject<object>();

  httpOptions = {
    headers: new HttpHeaders({
      "Accept": "*/*",
      "Content-Type": "multipart/form-data"
    })
  };

  constructor(private httpClient: HttpClient) {
  }

  updatedBaremeAutomobileSelection(data: object) {
    this.baremAutomobileSource.next(data);
  }

  updateObjectFilter(data: object){
    this._searchInput.pipe(
     // tap(() => this._loading$.next(true)),
      debounceTime(10),
      //tap(() => this._loading$.next(false))
    ).subscribe(result => {
      console.log('constructor data service ===== ', result)
      this.objectFilterSource.next(data)

    });
 }

  get(service: SERVICES, url: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.services[service] + url)
          .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
          );
    });
  }

  post(service: SERVICES, url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.services[service] + url, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  upload(service: SERVICES, url: string, data: any) {
    const formData = new FormData();
    formData.append("file", data);
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.services[service] + url, formData).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postwithtoken(service: SERVICES, url: string, data: any) {
    return new Promise((resolve, reject) => {
    
      this.httpClient.post(environment.services[service] + url, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  put(service: SERVICES, url: string, id: string, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.services[service] + url + '/' + id, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  patch(service: SERVICES, url: string, id: string, data: any) {
    return new Promise((resolve, reject) => {
      this.httpClient.patch(environment.services[service] + url + '/' + id, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  delete(service: SERVICES, url: string, id?: string) {
    if (id) {
      return new Promise((resolve, reject) => {
        this.httpClient.delete(environment.services[service] + url + '/' + id).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        this.httpClient.delete(environment.services[service] + url).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
  }
}
