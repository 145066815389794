import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { SERVICES } from '../models/helpers/utils.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {


  statsCommisions: any;

  constructor(private httpClient: HttpClient) {
    this.getStatsCommissions()
  }

  getStatsCommissions(filter = undefined) {
    return this.httpClient.post(environment.services[SERVICES.USER_MANAGEMENT] + 'stats/commissions', {filter})
  }
  getStatsSinistres(filter = undefined) {
    return this.httpClient.post(environment.services[SERVICES.USER_MANAGEMENT] + 'stats/sinistres', {filter})
  }
  getPolicecStats(filter = undefined) {
    return this.httpClient.post(environment.services[SERVICES.USER_MANAGEMENT] + 'police/stats', {filter})
  }
}
