
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { SERVICES } from '../models/helpers/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class ReleaseManagementService {

  constructor(private dataService: DataService) { }



  createRelease(releaseInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'release-management', releaseInfo)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  updateRelease(id: any, releaseInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'release-management', id, releaseInfo)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getReleaseById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/${id}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getLastReleaseBySubscription(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/bySubscription/${id}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getAllReleaseManagement(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getAllReleaseManagementBetweenTwoDatesByFilter(fromDate: any, toDate: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/betweenTwoDatesByFilter/${fromDate}/${toDate}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getAllReleaseManagementBetweenTwoDatesByPageAndLimit(fromDate: any, toDate: any, pageNum, itemsPerPage): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/betweenTwoDatesByPageAndLimit/${fromDate}/${toDate}/${pageNum}/${itemsPerPage}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  countAllReleaseManagementBetweenTwoDates(fromDate: any, toDate: any): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/countbetweenTwoDates/${fromDate}/${toDate}`)
        .then(
          (res: number) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }


  getAllReleaseManagementBetweenTwoDates(fromDate: any, toDate: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `release-management/betweenTwoDates/${fromDate}/${toDate}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  generateReleaseInvoice(invoice: any, id: any) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `GeneratePDF/release/${id}`, invoice)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  generateContratAssuranceAutomobile(invoice: any, id: any) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `GeneratePDF/contratAssuranceAutomobile/${id}`, invoice)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  generateattestionCompagny(invoice: any, id: any) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `GeneratePDF/attestionCompagnies/${id}`, invoice)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  generateattestionCompagnyBlank(invoice: any, id: any) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, `GeneratePDF/attestionCompagniesBlank/${id}`, invoice)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })
  }

  deleteRelease(id: any) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.USER_MANAGEMENT, 'release-management', id)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

}
