import {
  Component, Input, OnInit, Output, EventEmitter, QueryList,
  ViewChildren, SimpleChanges, OnChanges, OnDestroy
} from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { KeyValue } from '@angular/common';
import { IFilter } from '../../models/helpers/utils.interface';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { Observable, Subject } from 'rxjs';
import { SearchService } from './search.service';
import { DataService } from 'app/shared/data/data.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
  providers: [NgbPaginationConfig]
})
export class TablesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() Title = 'Title';
  @Input() Subtitle = '';
  @Input() HeaderData = [] as any;
  @Input() ButtonsContent = {} as any;
  @Input() ContentData = [] as any;
  @Input() listTotal = [] as any;
  @Input() KeyData = [] as any;
  @Input() FilterData = [];
  @Input() dataFilter = []
  @Input() paginationTotal: number;
  @Input() paginationPage: number;
  @Input() paginationPageSize: number;

  @Input() isExpert = false;
  @Input() isSearch = false;
  @Input() paginationIsManual = false;
  @Input() isFilter = false;
  @Input() isFilter2 = false;
  @Input() activeActions = true;
  @Input() isEdit = true;
  @Input() isDelete = false;
  @Input() isJoinFile = false;
  @Input() isDetail = false;
  @Input() isAddStakeholder = false;
  @Input() isAddProposal = false;
  @Input() isLock = false;
  @Input() isUnLock = false;
  @Input() isActivated = false;
  @Input() isSelected = false;
  @Input() isTotal = false;

  @Input() optionsFilter: IFilter[] = [];
  @Input() isContentImage = false;
  @Input() inputsOutputsBaremes = [];
  @Input() outputsBaremes = [];
  @Input() isBareme = false;
  @Input() inputsBareme = []
  @Input() outputsBareme = [];
  @Input() filterObject: any = {};

  @Output() fn_edit = new EventEmitter<string>();
  @Output() fn_delete = new EventEmitter<string>();
  @Output() fn_join_file = new EventEmitter<any>();
  @Output() fn_detail = new EventEmitter<string>();
  @Output() fn_assign_expert = new EventEmitter<string>();
  @Output() fn_add_stakeholder = new EventEmitter<string>();
  @Output() fn_add_proposal = new EventEmitter<string>();
  @Output() fn_lock = new EventEmitter<string>();
  @Output() fn_unlock = new EventEmitter<string>();
  @Output() fn_loadPageData = new EventEmitter<number>();
  @Output() fn_is_activated = new EventEmitter<string>();

  @Output() fn_is_selected = new EventEmitter<string>();
  @Output() fn_filter_item_selected = new EventEmitter<string>();
  @Output() getInputsOutputs = new EventEmitter<any>();
  @Output() updateInpuOutPut = new EventEmitter<any>();
  @Output() fn_handleFilterInput = new EventEmitter<any>();
  @Output() fn_resetFilter = new EventEmitter<any>();

  page = 1;
  pageSize = 10;
  canFilter = false;
  filterText = '';
  fitlerObject = [];



  objects$: Observable<any[]>;
  total$: Observable<number>;
  isLoading = false;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  constructor(config: NgbPaginationConfig,
    private service: SearchService,
    private dataService: DataService) {
    // customize default values of paginations used by this component tree
    config.boundaryLinks = true;
    this.isLoading = true;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.ContentData.length !== 0) {
      this.setArrayAndKeys();
    }

  }

  ngOnInit(): void {
    this.fn_can_filter();
    this.removeFirstAndLast();
  }

  ngOnDestroy(): void {
    this.service.destroyObservable();
  }

  fn_can_filter(): void {
    this.canFilter = (this.optionsFilter.length !== 0)
  }

  removeFirstAndLast() {
    this.fitlerObject = this.getHeadersOfTable(this.ContentData)?.slice(1)
  }

  counter(i: number) {
    return new Array(i);
  }

  updateItem(value: string) {
    this.fn_edit.emit(value);
  }

  detailItem(value: string) {
    this.fn_detail.emit(value);
  }

  assignExpert(value: string) {
    this.fn_assign_expert.emit(value);
  }

  deleteItem(value: string) {
    this.fn_delete.emit(value);
  }

  joinFileItem(value: string, isExpert) {
    this.fn_join_file.emit({ value, isExpert });
  }

  addStakeholder(value: string) {
    this.fn_add_stakeholder.emit(value);
  }

  addProposal(value: string) {
    this.fn_add_proposal.emit(value);
  }
  activeUser(value: string) {
    this.fn_is_activated.emit(value);
  }

  elementIsSelect(value: string) {
    this.fn_is_selected.emit(value);
  }

  lock(value: string) {
    this.fn_lock.emit(value);
  }

  unlock(value: string) {
    this.fn_unlock.emit(value);
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  // Order by ascending property value
  valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.value.localeCompare(b.value);
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  filterTable($event) {
    this.filterText = $event.target.value;
  }

  loadPage($event) {
    this.fn_loadPageData.emit(this.paginationPage);
  }

  getHeadersOfTable(tab: any[]): string[] {
    const result = []
    if (tab.length > 0) {
      // tslint:disable-next-line:forin
      for (const key in tab[0]) {
        result.push(key)
      }
    }
    return result
  }


  handleInputOutput(value) {
    this.getInputsOutputs.emit(value)
  }

  saveInputOutPut() {
    this.updateInpuOutPut.emit();
  }

  handleFilterInput(event, item) {
    this.fn_handleFilterInput.emit(this.filterObject);
  }
  resetFilterInput(){
    this.filterObject.reset();
   // this.fn_resetFilter.emit();
  }

  async setArrayAndKeys(): Promise<void> {
    console.log("on changes,", this.ContentData)
    this.service.setArray = this.ContentData;
    this.service.setKeys = this.KeyData;
    this.objects$ = this.service.objects$;
    this.total$ = this.service.total$;
    this.service.callSearch;
    console.log("objects", this.objects$)
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  filterSearchTerm(value) {
    this.fn_filter_item_selected.emit(value)
  }
}
