import {Routes, RouterModule} from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../home/page.module').then(m => m.PageModule)
  }
];
