import { Branche } from './branche';

export class Coords {
  latitude: number;
  longitude: number;
}

export class AccessoryFeeSchedule {
  startingPrice: number
  endPrice: number
  accessory: number
}


export class Partner {
  id: string;
  _id: string;
  socialReason?: string;
  legalForm?: string;
  address?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  code?: number;
  email?: string;
  logo?: string;
  coords?: Coords;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  isActif: boolean;
  branches: Branche[];
  listAccessoryFeeSchedule: AccessoryFeeSchedule[];






  constructor(options: {
    socialReason?: string,
    legalForm?: string,
    address?: string,
    phoneNumber1?: string,
    phoneNumber2?: string,
    code?: number,
    email?: string,
    logo?: string,
    coords?: Coords,
    listAccessoryFeeSchedule?: AccessoryFeeSchedule[]
  } = {}) {
    this.socialReason = options.socialReason || '';
    this.legalForm = options.legalForm || '';
    this.address = options.address || '';
    this.phoneNumber1 = options.phoneNumber1 || '';
    this.phoneNumber2 = options.phoneNumber2 || '';
    this.code = options.code || 0;
    this.email = options.email || '';
    this.logo = options.logo || '';
    this.listAccessoryFeeSchedule = options.listAccessoryFeeSchedule || [];
    this.coords = {
      latitude: options.coords?.latitude || 0,
      longitude: options.coords?.longitude || 0,
    }
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.isDeleted = false;
    this.isActif = true;
  }
}
