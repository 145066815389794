
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { SERVICES } from '../models/helpers/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class SlipManagementService {

  constructor(private dataService: DataService) { }



  createProductionSlips(productionSlips: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'ProductionSlips', productionSlips)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  createReceiptSlips(receiptSlips: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'ReceiptSlips', receiptSlips)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }


  getAllReceiptSlips(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'ReceiptSlips')
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getAllProductionSlips(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'ProductionSlips')
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getAllProductionSlipsByCompany(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, `ProductionSlips/byCompany/${id}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  deleteProductionSlipById(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.delete(SERVICES.USER_MANAGEMENT, `ProductionSlips/${id}`)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  getLastReceiptSlip(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.get(SERVICES.USER_MANAGEMENT, 'ReceiptSlips/lastReceiptSlip')
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }
  updateReceiptSlip(id: any, receiptSlipInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'ReceiptSlips', id, receiptSlipInfo)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

  updateProductionSlip(id: any, receiptSlipInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.patch(SERVICES.USER_MANAGEMENT, 'ProductionSlips', id, receiptSlipInfo)
        .then(
          (res: any) => {
            resolve(res)
          }
        )
        .catch(
          (err: any) => {
            reject(err)
          }
        )
    })

  }

}
