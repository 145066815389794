import { RouteInfo } from './vertical-menu.metadata';
// Sidebar menu Routes and data

const role = JSON.parse(localStorage.getItem('user_info'))
let routage: RouteInfo[] = []


  routage = [
    {
      path: '/home/accueil',
      title: 'Accueil',
      icon: 'ft-home',
      hidden: true,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    {
      path: '/home/plans',
      title: 'Gestion des Plans',
      icon: 'ft-home',
      hidden: false,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    {
      path: '/home/plan-sante',
      title: 'Plans Santé',
      icon: 'ft-activity',
      hidden: false,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    {
      path: '/home/condition-particuliere',
      title: 'Condition particulière',
      icon: 'ft-home',
      hidden: false,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    {
      path: '',
      title: 'Soins de santé',
      icon: 'ft-activity',
      class: 'has-sub',
      hidden: false,
      badge: '2',
      badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: [
        {
          path: '/home/health-care/drug',
          title: 'Médicaments',
          icon: 'ft-droplet submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/health-care/medical-act',
          title: 'Actes Médicaux',
          icon: 'ft-file-text submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Prestataires',
          icon: 'ft-award submenu-icon',
          class: 'has-sub',
          hidden: false,
          badge: '1',
          badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/prestataire/activate-account',
              title: 'Liste Prestataires',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/prestataire/prestations',
              title: 'Factures',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/prestataire/devis',
              title: 'Devis',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            // {
            //   path: '/home/prestataire/actes-medicaux',
            //   title: 'Actes Médicaux',
            //   icon: 'ft-droplet submenu-icon',
            //   class: '',
            //   badge: '',
            //   badgeClass: '',
            //   isExternalLink: false,
            //   submenu: []
            // },
          ]
        },
      ]
    },
    {
      path: '/home/sinisters',
      title: 'Sinistres',
      icon: 'ft-award',
      hidden: false,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },
    // {
    //   path: '/home/releases',
    //   title: 'Liste Quittances',
    //   icon: 'ft-award',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // },
    // {
    //   path: '/home/receipts',
    //   title: 'Liste Reçus',
    //   icon: 'ft-award',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // },
    {
      path: '',
      title: 'Bordereaux',
      icon: 'ft-activity',
      hidden: false,
      class: 'has-sub',
      badge: '2',
      badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: [
        {
          path: '/home/commissions',
          title: 'Gestion Bordereaux',
          icon: 'ft-droplet submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/slips',
          title: 'Cloture Bordereaux',
          icon: 'ft-file-text submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/payment-management/slips',
          title: 'Payment Bordereaux',
          icon: 'ft-file-text submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
      ]
    },
    // {
    //   path: '/home/commissions',
    //   title: 'Bordereaux',
    //   icon: 'ft-award',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // },
    // {
    //   path: '/home/slips',
    //   title: 'Cloture',
    //   icon: 'ft-award',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // },
    // {
    //   path: '/home/payment-management/slips',
    //   title: 'Payment Bordereau',
    //   icon: 'ft-award',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // },
    // {
    //   path: '',
    //   title: 'Souscriptions',
    //   icon: 'ft-award',
    //   class: 'has-sub',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: [
    //     {
    //       path: '/home/subscriptions',
    //       title: 'Liste Souscriptions',
    //       icon: 'ft-droplet submenu-icon',
    //       class: '',
    //       badge: '',
    //       badgeClass: '',
    //       isExternalLink: false,
    //       submenu: []
    //     },
    //     {
    //       path: '/home/subscriptions/create-insured',
    //       title: 'Création Assuré',
    //       icon: 'ft-droplet submenu-icon',
    //       class: '',
    //       badge: '',
    //       badgeClass: '',
    //       isExternalLink: false,
    //       submenu: []
    //     },
    //   ]
    // },
    // {
    //   path: '',
    //   title: 'Police Groupé',
    //   icon: 'ft-award',
    //   class: 'has-sub',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: [
    //     {
    //       path: '/home/subscription-group/list',
    //       title: 'Liste police groupé',
    //       icon: 'ft-droplet submenu-icon',
    //       class: '',
    //       badge: '',
    //       badgeClass: '',
    //       isExternalLink: false,
    //       submenu: []
    //     },
    //     {
    //       path: '/home/legal-person/list',
    //       title: 'Liste entreprises',
    //       icon: 'ft-droplet submenu-icon',
    //       class: '',
    //       badge: '',
    //       badgeClass: '',
    //       isExternalLink: false,
    //       submenu: []
    //     },
    //     {
    //       path: '/home/physical-person/list',
    //       title: 'Liste personnes',
    //       icon: 'ft-droplet submenu-icon',
    //       class: '',
    //       badge: '',
    //       badgeClass: '',
    //       isExternalLink: false,
    //       submenu: []
    //     },
    //   ]
    // },
    {
      path: '',
      title: 'Souscription',
      icon: 'ft-activity',
      class: 'has-sub',
      hidden: false,
      badge: '2',
      badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: [
        {
          path: '/home/free-release',
          title: 'Quittancement Libre',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Individuel',
          icon: 'ft-droplet submenu-icon',
          class: 'has-sub',
          hidden: false,
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/subscriptions',
              title: 'Liste',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/subscriptions/add',
              title: 'Ajoute',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/physical-person/list',
              title: 'Liste Personnes',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },
        {
          path: '',
          title: 'Groupé',
          icon: 'ft-droplet submenu-icon',
          class: 'has-sub',
          hidden: false,
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/subscription-group/list',
              title: 'Liste',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/subscription-group/add',
              title: 'Ajoute',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/legal-person/list',
              title: 'Liste Entreprises',
              icon: 'ft-file-text submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },
      ]
    },
    {
      path: '/home/transactions',
      title: 'Transaction',
      icon: 'ft-award',
      hidden: false,
      class: '',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: []
    },   {
      path: '',
      title: 'Statistiques',
      icon: 'ft-award',
      class: 'has-sub',
      isExternalLink: false,
      submenu: [
        {
          path: '/home/statistiques',
          title: 'Menu Statistiques',
          icon: 'ft-droplet submenu-icon',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
      ]
    },
    {
      path: '',
      title: 'Paramètres',
      icon: 'ft-settings',
      class: 'has-sub',
      badge: '14',
      badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: [
        {
          path: '/home/settings/constants/promotion',
          title: 'Promotions',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Relance',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: 'has-sub',
          badge: '5',
          badgeClass: 'badge badge-pill badge-info float-right mr-1 mt-1',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/relance',
              title: 'Relance & Info',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/relance/list',
              title: 'Liste Relance',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/relance/arrieres',
              title: 'Liste Arriérés',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/relance/impayes',
              title: 'Liste Impayées',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/relance/envoyes',
              title: 'Relances Envoyés',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },

        {
          path: '',
          title: 'Attestations',
          icon: 'ft-arrow-right submenu-icon',
          class: 'has-sub',
          hidden: false,
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/certificate/create',
              title: 'Création Attestation',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/certificate/used-certificate',
              title: 'Attestations Utilisees',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/certificate/unused-certificate',
              title: 'Attestations Non Utilisees',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },
        {
          path: '/home/brokers',
          title: 'Courtier',
          icon: 'ft-award submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Distributeurs',
          icon: 'ft-award submenu-icon',
          class: 'has-sub',
          hidden: false,
          isExternalLink: false,
          submenu: [
            {
              path: '/home/distributors',
              title: 'Liste Distributeurs',
              icon: 'ft-droplet submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },
        {
          path: '/home/settings/constants/partner',
          title: 'Partenaires',
          icon: 'ft-briefcase submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/constants/branche',
          title: 'Branches',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/constants/risk',
          title: 'Risques',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/constants/garanties',
          title: 'Garanties',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/constants/garanties-compagnies',
          title: 'Compagnie-Garantie',
          icon: 'ft-arrow-right submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '',
          title: 'Configurations',
          icon: 'ft-sliders submenu-icon',
          class: 'has-sub',
          hidden: false,
          badge: '3',
          badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1',
          isExternalLink: false,
          submenu: [
            {
              path: '/home/settings/constants/baremes/auto',
              title: 'Barémes Auto',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/baremes/sante',
              title: 'Barémes Sante',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/baremes/voyage',
              title: 'Barémes Voyage',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/baremes/credit',
              title: 'Barémes Crédit',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/baremes/habitation',
              title: 'Barémes Habitation',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/baremes/assurance-individuelle',
              title: 'Barémes Assurance Individuelle',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/tarif',
              title: 'Tarification',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
            {
              path: '/home/settings/constants/element-form',
              title: 'E-Formulaire',
              icon: 'ft-arrow-right submenu-icon',
              hidden: false,
              class: '',
              badge: '',
              badgeClass: '',
              isExternalLink: false,
              submenu: []
            },
          ]
        },
        {
          path: '/home/settings/users',
          title: 'Profils & Utilisateurs',
          icon: 'ft-users submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/roles',
          title: 'Gestion des Rôles',
          icon: 'ft-users submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/home/settings/profil',
          title: 'Compte',
          icon: 'ft-user submenu-icon',
          hidden: false,
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
      ]
    },
  ];


export const ROUTES: RouteInfo[] = routage
