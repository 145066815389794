import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import AWSS3UploadAshClient from 'aws-s3-upload-ash';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  s3Client: AWSS3UploadAshClient;

  constructor(
  ) {
    const config = {
      bucketName: environment.BUCKET_NAME,
      dirName: 'v1',
      region: environment.AWS_REGION,
      accessKeyId: environment.AWS_ACCESS_KEY,
      secretAccessKey: environment.AWS_SECRET_KEY,
      s3Url: `https://${environment.BUCKET_NAME}.s3.amazonaws.com/`
    }
    this.s3Client = new AWSS3UploadAshClient(config);
  }


  async uploadFileIntoS3(fileSelected: File) {
   try {
      const data = await this.s3Client.uploadFile(
        fileSelected,
        fileSelected.type,
        undefined,
        fileSelected.name,
        "authenticated-read"
      );
      return data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
}
