import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ElementFormAssurance } from '../models/v1/element-form-assurance';
import { Formulaire } from '../models/v1/formulaire';
import { SelectBoxFormaulare } from '../models/v1/selectBoxFormulaire';
import { TextBoxFormaulare } from '../models/v1/textBoxFormulaire';

@Injectable()
export class FormulaireService {
  // TODO: get from a remote source of question metadata
  getFormulaires(elementForms: ElementFormAssurance[]) {

    const formulaires: Formulaire<string>[] = [];

    elementForms.forEach((el: any) => {
      switch(el.controlType) {
        case 'selectbox': 
          let options = [];
          el.options.forEach(op => {
            op.isActif ? options.push({
              key: op.cle,
              value: op.valeur
            }) : null;
          });
          formulaires.push(
            new SelectBoxFormaulare({
              key: el.key,
              label: el.label,
              options,
              order: el.order,
              required: el.required
            })
          )
        break;
        case 'textbox': 
          formulaires.push(
            new TextBoxFormaulare({
              key: el.key,
              label: el.label,
              type: el.type,
              value: '',
              required: el.required,
              order: el.order
            }),
          )
        break;
      }
    })

    return of(formulaires.sort((a, b) => a.order - b.order));
  }
}