export const environment = {
  production: true,
  services: {
    USER_MANAGEMENT: 'https://staging.proassur.kalpayinc.com/user-service/api/',
    // ASSURANCE_MANAGEMENT: 'https://staging.proassur.kalpayinc.com/assurance-service/api/',
    ASSURANCE_MANAGEMENT: 'http://localhost:3001/assurance-service/api/',
    PAYMENT_URL: 'https://staging.service-backend.kalpayinc.com',
    KALPAY_FILE_SERVICE: 'https://dev.service-backend.kalpayinc.com/fileservice/'
  },
  apiURL: 'https://staging.proassur.kalpayinc.com/user-service/api/',
  serverDownload: 'https://staging.proassur.kalpayinc.com/user-service/api/',
  BUCKET_NAME:'proassur-files',
  AWS_ACCESS_KEY: 'AKIAQ7YRTJKBMOBE4XMH',
  AWS_SECRET_KEY: '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',
  AWS_REGION: 'us-east-2',
  BUCKET_URL: "https://proassur-files.s3.amazonaws.com/v1/",
  API_KEY_GOOGLE: ""

};
export const paymentAccount = {
  "secretKey": "",
  "accessKey": ""
}
export let paymentToken = {
  'access_token': '',
  'refresh_token': '',
}


// export const environment = {
//   production: true,
//   services: {
//     USER_MANAGEMENT: 'http://localhost:3000/proassur-user-management/api/',
//     // ASSURANCE_MANAGEMENT: 'https://staging.proassur.kalpayinc.com/assurance-service/api/',
//     ASSURANCE_MANAGEMENT: 'http://localhost:3001/assurance-service/api/',
//     PAYMENT_URL: 'https://staging.service-backend.kalpayinc.com',
//     KALPAY_FILE_SERVICE: 'https://dev.service-backend.kalpayinc.com/fileservice/'
//   },
//   apiURL: 'http://localhost:3000/proassur-user-management/api/',
//   serverDownload: 'http://localhost:3000/proassur-user-management/api/',
//   BUCKET_NAME:'proassur-files',
//   AWS_ACCESS_KEY: 'AKIAQ7YRTJKBMOBE4XMH',
//   AWS_SECRET_KEY: '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',
//   AWS_REGION: 'us-east-2',
//   BUCKET_URL: "https://proassur-files.s3.amazonaws.com/v1/"

// };
// export const paymentAccount = {
//   "secretKey": "",
//   "accessKey": ""
// }
// export let paymentToken = {
//   'access_token': '',
//   'refresh_token': '',
// }
