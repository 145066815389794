enum AccessType {
  CREDENTIALS,
  PHONE_NUMBER
}

export class BusinessInfo {
  name: string
  description: string
  address: string
  codePostal: string
  country: string
  phoneNumber: string
  email: string
}

export interface IAccount {
  id: string;
  _id: string;
  businessInfo: BusinessInfo
  metadata: any;
  username: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  image: string;
  failedLoginCount: number;
  isApproved: boolean;
  isVerified: boolean;
  isLocked: boolean;
  isAutoVerified: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class AccountDTO {
  account?: {
    businessInfo?: BusinessInfo,
    username?: string,
    email?: string,
    phoneNumber?: string,
    address?: string,
    user?: string
  }
  defaultAccessType?: AccessType
  phoneNumberAccess?: {
    pin?: string
  }
  credentialsAccess?: {
    password?: string
  }
  roleName?: string

  constructor(options: {
    account?: {
      businessInfo?: BusinessInfo,
      username?: string,
      email?: string,
      phoneNumber?: string,
      address?: string,
      user?: string,
    },
    defaultAccessType?: AccessType,
    phoneNumberAccess?: {
      pin?: string,
    },
    credentialsAccess?: {
      password?: string,
    },
    roleName?: string
  } = {}) {
    this.account = {
      businessInfo: options.account?.businessInfo,
      username: options.account?.username || '',
      email: options.account?.email || '',
      phoneNumber: options.account?.phoneNumber || '',
      address: options.account?.address || '',
      user: options.account?.user || ''
    }
    this.defaultAccessType = options.defaultAccessType || AccessType.CREDENTIALS;
    this.phoneNumberAccess = {
      pin: options.phoneNumberAccess?.pin || '000000'
    };
    this.credentialsAccess = {
      password: options.credentialsAccess?.password || 'Proassur@2021'
    };
    this.roleName = options.roleName || null;
  }
}
