export interface ButtonInterface {
  icon: string;
  name: string;
  class: string;
  is_fn_back: boolean;
  url_redirect: string;
}

interface IOptions {
  key: string;
  value: string;
}

export interface IFilter {
  libelle: string;
  options: IOptions[]
}

export const PHONE_NUMBER_REGEX = '(^[7]{1}[05678]{1}[0-9]{7}){1,9}$'
export const EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'

export enum SERVICES {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  ASSURANCE_MANAGEMENT = 'ASSURANCE_MANAGEMENT',
  PAYMENT_URL = 'PAYMENT_URL',
  KALPAY_FILE_SERVICE= 'KALPAY_FILE_SERVICE'
}
