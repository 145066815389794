import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { ILogin } from '../models/helpers/login';
import { IAccount } from '../models/v1/account';
import { SERVICES } from '../models/helpers/utils.interface';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {

  private httpClient: HttpClient
  constructor(
    public router: Router,
    private dataService: DataService,
    handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  setToken(token: string) {
    sessionStorage.setItem('accessToken', token);
  }

  setAccount(account: any) {
    sessionStorage.setItem('currentAccount', JSON.stringify(account));
  }

  setRole(roleName: any) {
    sessionStorage.setItem('role', JSON.stringify(roleName));
  }

  setPermissions(permissionsList: any) {
    sessionStorage.setItem('permissions', JSON.stringify(permissionsList));
  }

  async hasPermission(permission: string) {
    const permissions = this.getCurrentUserPermissions();
    const p = await permissions.filter(element => element?.permission?.name === permission)
    return p.length === 0 ? false : true
  }

  getCurrentUserPermissions() {
    return JSON.parse(sessionStorage.getItem('permissions'))
  }

  getCurrentAccount(): IAccount {
    return JSON.parse(sessionStorage.getItem('currentAccount')) as unknown as IAccount;
  }

  getToken(): string {
    const token = sessionStorage.getItem('accessToken');
    if (window.location.pathname.split('/')[2] !== 'verify') {
      if (token) {
        return token;
      } else {
        this.logout();
      }
    }
  }

  /* getCurrentAccount(): Promise<IAccount> {
    return new Promise<IAccount>((resolve, reject) => {
      this.dataService.get(`Accounts/${this.getCurrentAccountId()}`)
        .then(
          (account: IAccount) => {
            resolve(account);
          }
        )
    })
  } */

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser({ login, password }: { login: string, password: string }): Promise<{ message: string }> {
    return new Promise<{ message: string }>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'GrantAccess/login', {
        login,
        password
      })
        .then(
          (res: { message: string }) => {
            resolve(res)
          },
          (error) => {
            reject(error)
          }
        )
    })

  }
  signinUserPhone({ login }: { login: string }): Promise<{ message: string }> {
    return new Promise<{ message: string }>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'GrantAccess/login-phone', {
        phoneNumber: login
      })
        .then(
          (res: { message: string }) => {
            resolve(res)
          },
          (error) => {
            reject(error)
          }
        )
    })

  }



  forgotPassword(payload: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(environment.services[SERVICES.USER_MANAGEMENT] + 'GrantAccess/credentials/forgotpassword', {
        identifiant: payload.login
      }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    })

  }

  resetPassword(payload: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(environment.services[SERVICES.USER_MANAGEMENT] + 'GrantAccess/credentials/resetpassword', {
        password: payload.password,
        code: payload.code
      }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    })

  }

  validateUser({ otp, identifiant, validationType }: { otp: string, identifiant: string, validationType: string }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.dataService.post(SERVICES.USER_MANAGEMENT, 'GrantAccess/validate', { otp, identifiant, validationType })
        .then(
          (res: any) => {
            if (res) {
              this.setToken(res?.access_token)
              this.setAccount(res?.account)
              this.setRole(res?.role)
              this.setPermissions(res?.permissions)
              resolve(res)
            }
            reject(new Error('Please try again.'))
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear()
    localStorage.setItem("logout", "true")
    this.router.navigate(['/pages/login']);

    // window.location.reload();
  }

  isAuthenticated() {
    return !!sessionStorage.getItem('accessToken');
  }
}
