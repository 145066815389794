<!--Dark table starts-->
<section id="dark">
    <div class="row">
        <div class="col-12" *ngIf="canFilter">
            <div class="users-list-filter px-2">
                <form novalidate="" class="ng-untouched ng-pristine ng-valid">
                    <div class="row border rounded py-2 mb-2 mx-n2">
                        <div class="col-12 col-sm-6 col-lg-3" *ngFor="let filter of optionsFilter,let i=index">
                            <label for="users-list-verified">{{filter?.libelle}}</label>
                            <fieldset class="form-group">
                                <select id="users-list-verified" class="form-control" (change)="filterTable($event)">
                                    <option value="" selected>Selectionner un {{filter?.libelle?.toLowerCase()}}
                                    </option>
                                    <option *ngFor="let option of filter?.options" value="{{option?.key}}">
                                        {{option?.value}}
                                    </option>
                                </select>
                            </fieldset>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                            <button type="reset" (click)="filterText = ''"
                                class="btn btn-primary btn-block users-list-clear glow mb-0"> Réinitialiser
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ Title }}</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <p *ngIf="Subtitle">{{ Subtitle }}</p>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="d-flex justify-content-between p-2">
                                   
                                </div>
                            </div>

                            <div *ngIf="isBareme == true && ContentData.length >= 1 && ContentData[0].id != 1" class="col-md-5"
                                style="margin-top: -80px !important; margin-left: 200px !important;">
                                <div *ngIf="isBareme == true" style="margin-bottom: 10px;">
                                    <div>
                                        <button class="text-primary border-2 border-primary btn-valider"
                                            style="font-size: 10; margin-left: 400px !important;" id="modif"
                                            (click)="saveInputOutPut()">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                            Sauvegarder
                                        </button>
                                    </div>

                                    <label class="text-secondary">
                                        Inputs Barémes

                                    </label>
                                    <ng-select #myselectInput [items]="inputsOutputsBaremes" bindLabel="item"
                                        bindValue="item" [multiple]="true" [closeOnSelect]="false"
                                        placeholder="Inputs Barémes" (change)="handleInputOutput($event)"
                                        [(ngModel)]="inputsBareme">
                                    </ng-select>
                                </div>
                                <div style="margin-bottom: 5px;">
                                    <label class="text-secondary">Outputs Barémes </label>
                                    <ng-select #myselectInput [items]="inputsOutputsBaremes" bindLabel="item"
                                        bindValue="item" [multiple]="false" [closeOnSelect]="true"
                                        placeholder="Outputs Barémes" (change)="handleInputOutput($event)"
                                        [(ngModel)]="outputsBareme">
                                    </ng-select>
                                </div>
                            </div>

                        </div>


                        <div class="table-responsive">
                            <table class="table table-hover table-striped table-bordered m-0">
                                <thead class="thead-light">
                                    <tr *ngIf="HeaderData.length > 0">
                                        <th width="10">{{HeaderData[0]}}</th>
                                        <ng-container *ngFor="let header of HeaderData | slice:1">
                                            <th class="text-center">{{ header }}</th>
                                        </ng-container>
                                    </tr>
                                </thead>

                                <tbody >
                                    <tr *ngIf="isFilter">
                                        <ng-container *ngFor="let item of dataFilter">

                                            <td *ngIf="item.value != '' && item.type != 'button'" >
                                                <form [formGroup]="filterObject">
                                                    <div *ngIf="!item.option">
                                                        <input 
                                                               type="{{item.type}}"
                                                               placeholder="{{item.value}}"
                                                               (change)="handleFilterInput($event.target.value, item)"
                                                               [value]="filterObject[item.value]"
                                                               formControlName="{{item.value}}"
                                                               class="form-control"
                                                               style="width: auto; margin-left: 10px; background-color: white !important;" name=""

                                                            />
                                                    </div>
                                                    <div *ngIf="item.option == true">
                                                        <select  class="custom-select"  class="custom-select"
                                                            style="width: auto; margin-left: 10px;background-color: white !important;" name=""
                                                            placeholder="{{item.value}}"
                                                            (change)="handleFilterInput($event.target.value, item)"
                                                            formControlName="{{item.value}}"
                                                            >
                                                            <option *ngFor="let itemPart of FilterData" [value]="itemPart.id">{{
                                                                itemPart.socialReason }}</option>
                                                        </select>
                                                    </div>
                                                </form>
                                               
                                            </td>
                                            <td *ngIf="item.value == '' && item.type != 'button'">
                                                <div>
                                                </div>
                                            </td>
                                            <td *ngIf="item.type == 'button'">
                                                <div>
                                                    <button class="btn btn-warning btn-icon mr-1 mb-1"
                                                    (click)="resetFilterInput()"
                                                    >
                                                        {{item.value}}
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <ng-container 
                                    *ngFor="let content of ContentData | slice: (page-1) * pageSize : page * pageSize | filter: filterText: fitlerObject, let j=index">
                                    <tr *ngIf="ContentData.length >= 1 && ContentData[0].id != 1">
                                            <th scope="row" *ngIf="isSelected">
                                                <div class="mt-1">
                                                    <div class="checkbox"><input type="checkbox"
                                                            [attr.id]="'dash-checkbox'+ j" checked=""
                                                            (click)="elementIsSelect(j)"><label for="dash-checkbox4"
                                                            [attr.for]="'dash-checkbox'+ j"></label>
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="row" *ngIf="!isContentImage">{{ j + 1 }}</th>
                                            <ng-container
                                            *ngFor="let res of content | keyvalue: originalOrder , let i=index">
                                            <td
                                                *ngIf="res.key != 'id' && res.key != '_id' && res.value != 'button' && res.key != 'image' && res.key != 'formule'">
                                                {{ res.value }}</td>

                                            <td
                                                *ngIf="res.key != 'id' && res.key != '_id' && res.value != 'button' && res.key != 'image' && res.key == 'formule'">
                                                <ng-container *ngFor="let item of res.value">
                                                    <button type="button" disabled
                                                        class="{{item?.css}} mr-1">{{item?.value}}</button>
                                                </ng-container>
                                            </td>
                                            <td
                                                *ngIf="res?.key == 'image' && res.key != 'id' && res.key != '_id' && res.value != 'button'">
                                                <img [src]="res.value"
                                                    style='display:block; width:100px;height:100px; margin-bottom: 50px;' />
                                            </td>
                                            <td
                                                *ngIf="res.key != 'id' && res.key != '_id' && res.value == 'button'">
                                                <button type="button"
                                                    (click)="assignExpert(content.id ?content['id'] : content['_id'])"
                                                    [class]="ButtonsContent['expert'].class">
                                                    <i [class]="ButtonsContent['expert'].icon"></i>
                                                    {{ButtonsContent['expert'].name}}
                                                </button>
                                            </td>
                                        </ng-container>
                                            <td class="text-center" *ngIf="activeActions">
                                                <button *ngIf="isEdit" type="button"
                                                    class="btn btn-success btn-icon mr-1 mb-1"
                                                    (click)="updateItem(content.id ?content['id'] : content['_id'])">
                                                    <i class="ft-edit"></i>
                                                </button>
                                                <button *ngIf="isDetail" type="button"
                                                    class="btn btn-info btn-icon mr-1 mb-1"
                                                    (click)="detailItem(content.id ?content['id'] : content['_id'])">
                                                    <i class="ft-eye"></i>
                                                </button>
                                                <button *ngIf="isDelete" type="button"
                                                    class="btn btn-danger bg-light-danger btn-icon mr-1 mb-1"
                                                    (click)="deleteItem(content.id ?content['id'] : content['_id'])">
                                                    <i class="ft-trash"></i>
                                                </button>
                                                <button *ngIf="isJoinFile" type="button"
                                                    class="btn btn-primary bg-light-primary btn-icon mr-1 mb-1"
                                                    (click)="joinFileItem(content.id ?content['id'] : content['_id'], isExpert)">
                                                    <i class="ft-paperclip"></i>
                                                </button>
                                                <button *ngIf="isAddStakeholder" type="button"
                                                    class="btn btn-primary bg-light-primary btn-icon mr-1 mb-1"
                                                    (click)="addStakeholder(content.id ?content['id'] : content['_id'])">
                                                    <i class="ft-user-plus"></i>
                                                </button>
                                                <button *ngIf="isAddProposal" type="button"
                                                    class="btn btn-primary bg-light-primary btn-icon mr-1 mb-1"
                                                    (click)="addProposal(content.id ?content['id'] : content['_id'])">Estimer
                                                    le coût du
                                                    sinistre</button>
                                                <button *ngIf="content.isLock != 'Bloqué' && isLock" type="button"
                                                    class="btn btn-primary bg-light-primary btn-icon mr-1 mb-1"
                                                    (click)="lock(content.id ?content['id'] : content['_id'])"><i
                                                        class="ft-lock"></i></button>
                                                <button *ngIf="content.isLock == 'Bloqué' && isUnLock" type="button"
                                                    class="btn btn-primary bg-light-primary btn-icon mr-1 mb-1"
                                                    (click)="unlock(content.id ?content['id'] : content['_id'])"><i
                                                        class="ft-unlock"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="isTotal">
                                        <td *ngFor="let total of listTotal">{{ total }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="!isSearch && !paginationIsManual" class="row justify-content-md-center">
                        <div class="col-md-auto">
                            <ngb-pagination [style]="{}" [collectionSize]="ContentData.length" [pageSize]="pageSize"
                                [(page)]="page" [maxSize]="5" [rotate]="true"></ngb-pagination>
                        </div>
                    </div>


                    <div *ngIf="isSearch && !paginationIsManual" class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page"
                            [pageSize]="service.pageSize" [maxSize]="5" [rotate]="true">
                        </ngb-pagination>

                        <select class="custom-select" style="width: auto" name="pageSize"
                            [(ngModel)]="service.pageSize">
                            <option [ngValue]="10">10 éléments par page</option>
                            <option [ngValue]="20">20 éléments par page</option>
                            <option [ngValue]="30">30 éléments par page</option>
                            <option [ngValue]="40">40 éléments par page</option>
                            <option [ngValue]="50">50 éléments par page</option>
                        </select>
                    </div>

                    <div *ngIf="paginationIsManual" class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="paginationTotal" [(page)]="paginationPage"
                            (pageChange)="loadPage($event)" [pageSize]="paginationPageSize" [maxSize]="5"
                            [rotate]="true">
                        </ngb-pagination>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Dark table Ends-->