import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormulaireControlService} from 'app/shared/data/formulaire-control.service';
import {Formulaire} from 'app/shared/models/v1/formulaire';

@Component({
  selector: 'app-e-formulaire',
  templateUrl: './e-formulaire.component.html',
  styleUrls: ['./e-formulaire.component.scss']
})
export class EFormulaireComponent implements OnInit {

  @Input() formulaires: Formulaire<string>[] = [];
  form: FormGroup;
  payLoad = '';
  @Output() submit_form = new EventEmitter<string>();

  constructor(private qcs: FormulaireControlService) {
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formulaires);
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    this.submit_form.emit(this.payLoad);
  }

}
