<div [formGroup]="form" class="form-group controls element">
    <label [attr.for]="formulaire.key" class="text-secondary">{{formulaire.label}} <span *ngIf="formulaire.required" class="text-danger">*</span></label>

    <div [ngSwitch]="formulaire.controlType">

      <input
          *ngSwitchCase="'textbox'"
          [formControlName]="formulaire.key"
          [id]="formulaire.key"
          [type]="formulaire.type"
          class="form-control"
      >

      <select [id]="formulaire.key" *ngSwitchCase="'dropdown'" class="form-control" [formControlName]="formulaire.key">
        <option *ngFor="let opt of formulaire.options" [value]="opt.key">{{opt.value}}</option>
      </select>

    </div>

    <div class="errorMessage" *ngIf="!isValid">{{formulaire.label}} is required</div>
</div>
