import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Formulaire } from '../models/v1/formulaire';

@Injectable()
export class FormulaireControlService {
  constructor() { }

  toFormGroup(formulaires: Formulaire<string>[] ) {
    const group: any = {};

    formulaires && formulaires.forEach(question => {
      group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
                                              : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }
}