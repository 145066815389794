import {Injectable, PipeTransform} from '@angular/core';

import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {SortDirection} from './sortable.directive';

interface SearchResult {
  objects: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(objects: any[], column: string, direction: string): any[] {
  if (direction === '') {
    return objects;
  } else {
    return [...objects].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(object: any, term: string, pipe: PipeTransform, keys: any[]) {
  for (const key of keys) {
    if (object[`${key}`]?.toString().toLowerCase().includes(term?.toLowerCase())) {
      return true;
    }
  }
  return false;
}

@Injectable({providedIn: 'root'})
export class SearchService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _objects$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private OBJECTS = [];
  private KEYS = [];

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(private pipe: DecimalPipe) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._objects$.next(result.objects);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  get objects$() { return this._objects$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get callSearch() { this._search(); return null; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
  set setArray(array: any[]) {
    this._objects$.next(array);
    this.OBJECTS = array;
  }
  set setKeys(keys: any[]) { this.KEYS = keys; }

  public destroyObservable() {
    // console.log('destroyObservalbe ')
    this._objects$.next([]);
    this.OBJECTS = [];
    // console.log('destroyObservalbe 1 _objects$ =  ', this._objects$)
    this._search$.next();
    this._total$.next(0);
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    // console.log('_search')
    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this._state;
    // console.log('destroyObservalbe 0 OBJECTS =  ', this.OBJECTS)
    // console.log('destroyObservalbe O _objects$ =  ', this._objects$)
    // 1. sort
    let objects = sort(this.OBJECTS, sortColumn, sortDirection);

    // 2. filter
    objects = objects.filter(object => matches(object, searchTerm, this.pipe, this.KEYS));
    const total = objects.length;

    // 3. paginate
    objects = objects.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({objects, total});
  }
}
