<div style="margin-left: 10%; margin-right: 10%; margin-top: 4%; margin-bottom: 4%;">
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="editForm">

      <div *ngFor="let formulaire of formulaires" class="form-row">
        <app-dynamic-form-assurance style="width: 100%;" class="element" [formulaire]="formulaire" [form]="form"></app-dynamic-form-assurance>
      </div>

      <div class="form-row">
        <button type="button"
                class="btn btn-primary mr-2"
                type="submit" [disabled]="!form.valid"
        >
          <i class="ft-check-square mr-1"></i>
             Enregistrer
        </button>
      </div>
    </form>
  </div>
