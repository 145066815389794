import {Component, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './shared/auth/auth.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {ConnectionService} from 'ng-connection-service';
import {CustomizeToastrService} from './shared/services/costomize-toastr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  status = 'ONLINE';
  isConnected = true;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  constructor(
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private connectionService: ConnectionService,
    private toastr: CustomizeToastrService
  ) {
    /* if (this.authService.isAuthenticated()) {
      this.router.navigate(['/home/accueil']);
    } */
    this.connectionService
      .monitor()
      .subscribe(isConnected => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.status = 'ONLINE';
          this.toastr.PositionTopCenterSuccess({
            title: this.status,
            message: ''
          })
        } else {
          this.status = 'OFFLINE';
          this.toastr.ClearToastButton()
          this.toastr.PositionTopCenterError({
            title: this.status,
            message: 'Aucun accès à Internet'
          })
        }
      })
    // this.executeIdle()
  }

  executeIdle(): void {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(100);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(100);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.router.navigate(['/pages/lock-screen']);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    if (this.authService.isAuthenticated()) {
      this.idle.watch()
      this.timedOut = false;
    } else {
      this.idle.stop();
    }

    // this.reset();
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
